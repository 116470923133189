import { DISPLAY_SIZE_CHANGE } from 'action-types'

const displayElement = () => {
  const width = window.innerWidth
  if (width <= 1024) return 1
  else return 0
}

interface State {
  value: number | null
}

const initial_state: State = {
  value: displayElement(),
}

const displaySize = (state = initial_state, action: any) => {
  if (action.type === DISPLAY_SIZE_CHANGE) {
    state.value = displayElement()
  }

  return state
}

export default displaySize
