import DeviceDetector from 'device-detector-js'
import { DeviceDetail } from 'interfaces'

const getDeviceType = (userAgentHeader: string): DeviceDetector.DeviceDetectorResult => {
  const deviceDetector = new DeviceDetector()
  const device = deviceDetector.parse(userAgentHeader)
  return device
}

function getDeviceInfo(userAgent: string) {
  let deviceInfo = getDeviceType(userAgent)
  let device: DeviceDetail = {
    os: deviceInfo.os?.name,
    osVersion: deviceInfo.os?.version,
    userAgent: userAgent,
    browser: deviceInfo.client?.name,
    browserVersion: deviceInfo.client?.version,
    modelType: deviceInfo.device?.type,
    type: deviceInfo.device?.type,
  }
  return device
}

export default getDeviceInfo
