import 'containers/Login/Login.scss'
import './root.scss'
import { FC, useEffect } from 'react'
import Loading from 'components/Shared/Loading'
import { useHistory, useLocation } from 'react-router-dom'
import decodeToken from 'utils/decodeToken'
import { getPaywallQueryParams, handleContinueLogin, handleSimLogin } from './helper'
import { facebookLogin, googleOAuthLogin, guestLogin, appleLogin } from 'utils/login'
import { getAuthToken, removeAuthToken } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { UPDATE_CHECKOUT_VIEW } from 'action-types'
import { TopupViews } from 'reducers/topUp'

const loginSources = ['paywall', 'amp_paywall', 'ios_paywall', 'android_paywall', 'paywall']
export interface LoginState {
  loading: boolean
  isSimLogin: boolean
  isContinueLogin: boolean
  email: string
  isRedirected: boolean
}

const RootPage: FC = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    let accessToken = getAuthToken()
    if (accessToken && decodeToken(accessToken).username.includes('fc_guest_')) {
      removeAuthToken()
      accessToken = null
    }

    const state = getPaywallQueryParams(location)
    if (state.landingPage === TopupViews.Rewards) {
      dispatch({ type: UPDATE_CHECKOUT_VIEW, payload: TopupViews.Rewards })
    }
    const language = state?.language

    const source = state?.loginSource || state?.topupSource || ''
    if (state?.loginSimEmail != null) {
      handleSimLogin(state.loginSimEmail, history)
    } else if (accessToken && loginSources.indexOf(source) > -1) {
      if (state?.bidMode === 'paywall' || state?.bidMode === 'tipjar') {
        handleContinueLogin(history)
      } else {
        history.push({ pathname: '/dashboard' })
      }
    } else {
      switch (state?.loginMethod) {
        case 'google':
          googleOAuthLogin(false, JSON.stringify(state))
          break
        case 'facebook':
          facebookLogin(false, JSON.stringify(state))
          break
        case 'apple':
          appleLogin(false, history)
          break
        case 'fewcents':
          history.replace({
            pathname: `/login/${encodeURIComponent(JSON.stringify(state))}`,
            state: { manual: true },
          })
          break
        case 'guest':
          guestLogin(state)
          history.replace({ pathname: '/login' })
          break
        default:
          if (source && accessToken) {
            history.replace({ pathname: '/dashboard' })
          } else {
            history.replace({ pathname: '/login' })
          }
      }
    }
  }, [])

  return <Loading />
}

export default RootPage
