import {
  EDIT_START_DATE,
  EDIT_END_DATE,
  UPDATE_DATA,
  UPDATE_NORMALISED_DATA,
  UPDATE_PUBLISHER,
} from 'action-types/transaction'
interface State {
  startDate: Date
  endDate: Date
  data: any[]
  normalisedData: any[]
  publishers: any[]
}

const now = new Date()
const endDate = new Date(now.getFullYear(), now.getMonth() + 1)
const startDate = new Date(now.getFullYear(), now.getMonth() - 1)

const initial_state: State = {
  startDate,
  endDate,
  data: [],
  normalisedData: [],
  publishers: [],
}

const transaction = (state = initial_state, action: any) => {
  switch (action.type) {
    case EDIT_START_DATE:
      return { ...state, startDate: action.date }
    case EDIT_END_DATE:
      return { ...state, endDate: action.date }
    case UPDATE_DATA:
      return { ...state, data: action.data }
    case UPDATE_NORMALISED_DATA:
      return { ...state, normalisedData: action.data }
    case UPDATE_PUBLISHER:
      return { ...state, publishers: action.publishers }
    default:
      return state
  }
}

export default transaction
