import { Reward } from './../interfaces/index'
import * as actions from './../action-types/index'

interface State {
  rewards: Reward[]
  totalCustomerRewards: any
  leadForm?: LeadForm
  showMoreRewards: boolean
  showMorePaymentOptions: boolean
}

export enum AnswerType {
  checkbox = 'checkbox',
  single_select_dropdown = 'single_select_dropdown',
  text = 'text',
  date = 'date',
  multi_select_dropdown = 'multi_select_dropdown',
  number = 'number',
  radio_button = 'radio_button',
  phone = 'phone',
  email = 'email',
  consent = 'consent',
  name = 'name',
  work_email = 'work_email'
}

export interface Question {
  id?: Number
  questionLabel: string
  answerType: AnswerType
  answerLength: Number
  options: string[]
  isHidden: boolean
  isRequired: boolean
  isActive: boolean
  isAnswerValid?: boolean
  answer?: any
}

export interface LeadForm {
  id?: number
  campaignId?: number
  description: string
  redirectUrl: string
  completedUrl: string
  failedUrl: string
  notificationUrl: string
  questions: Question[]
  internal: boolean
  traceId: string
  activePublishers: string[]
  pixelScript: string
}

const initial_state: State = {
  rewards: [],
  totalCustomerRewards: [],
  showMoreRewards: false,
  showMorePaymentOptions: false,
}

const rewards = (state = initial_state, action: any) => {
  switch (action.type) {
    case actions.UPDATE_REWARDS:
      return { ...state, rewards: action.rewards }
    case actions.UPDATE_TOTAL_CUSTOMER_REWARDS:
      return { ...state, totalCustomerRewards: action.totalCustomerRewards }
    case actions.UPDATE_LEAD_FORM:
      return { ...state, leadForm: action.payload }
    case actions.QUESTION_ANSWER_CHANGED:
      const { question, answer } = action.payload
      const questions = state.leadForm?.questions.map((q) => {
        return q.id === question.id ? { ...question, answer } : q
      })
      return { ...state, leadForm: { ...state.leadForm, questions } }
    case actions.SET_SHOW_MORE_PAYMENT_OPTIONS:
      return {
        ...state,
        showMorePaymentOptions: action.payload,
        showMoreRewards: action.payload ? false : state.showMoreRewards,
      }
    case actions.SET_SHOW_MORE_REWARDS:
      return {
        ...state,
        showMoreRewards: action.payload,
        showMorePaymentOptions: action.payload ? false : state.showMorePaymentOptions,
      }
    default:
      return state
  }
}

export default rewards
