import { UPDATE_DASHBOARD_FILTER, UPDATE_EXPAND } from 'action-types'

interface State {
  filter: string | null
  expand: boolean
}

const initial_state: State = {
  filter: null,
  expand: false,
}

const dashboard = (state = initial_state, action: any) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_FILTER:
      return { ...state, filter: action.filter }
    case UPDATE_EXPAND:
      return { ...state, expand: !state.expand }
    default:
      return state
  }
}

export default dashboard
