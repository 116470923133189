import { CheckoutParamModel } from './../interfaces/index'
import {
  CAN_TOP_UP,
  UPDATE_CHECKOUT_INFO,
  UPDATE_POPULAR_METHODS,
  UPDATE_BID_PRICE,
  UPDATE_ARTICLE_TITLE,
  UPDATE_ARTICLE_IMAGE_URL,
} from './../action-types/index'
import {
  ADD_PAYPAL_MSG,
  ANIMATE_TOPUP,
  MORE_PAYMENT_OPTION,
  UPDATE_BALANCE,
  UPDATE_CURRENCY_DATA,
  UPDATE_PAYMENT_METHODS,
  UPDATE_TOPUP_AMOUNT,
  UPDATE_EXTRA_POPULAR_METHODS,
  UPDATE_CHECKOUT_VIEW,
} from 'action-types'
import { getURlParams, setURLParams } from 'utils/sessionStorageFn'

export enum TopupViews {
  Rewards = 'rewards',
  Checkout = 'checkout',
  PaymentOptions = 'paymentOptions',
}

export interface PaymentMethod {
  active: boolean
  country: string

  currency: {
    allowDecimalCheckouts: boolean
    code: string
    displayString: string
    fcSurcharge: number
    fixedFee: number
    id: number
    maxCustomerWalletBalance: number
    maxFee: number
    minBalance: number
    minPrice: number
    minTopUpAmount: number
    percentFee: number
    stripeZeroDecimal: boolean
  }
  currencyCode: string
  defaultMethod: boolean
  fcSurcharge: number
  fewcentsGatewayId: string
  fixedFee: number
  gateway: string
  gatewayDisplayName: string
  gatewayId: string
  icon: string
  id: number
  instrument: {
    id: number
    name: string
    hibernateLazyInitializer: any
  }
  isFavourite: boolean
  logoUrl: string
  minAmount: number
  name: string
  option: string
  ordinality: number
  percentFee: number
  primary: boolean
  settlementDurationMinutes: number
  showPpsMessage: boolean
  ppsMessage: string
}

interface State {
  balance: number | null
  payMethodsNumber: any
  popularMethods: Array<PaymentMethod>
  bankMethods: Array<PaymentMethod>
  cardMethods: Array<PaymentMethod>
  googlePayMethod: any
  applePayMethod: any
  paypalMethod: any
  venmoMethod: any
  extraPopularMethods: Array<PaymentMethod>
  walletMethods: Array<PaymentMethod>
  paymentOption: string
  topUpAmount: number
  currency: any
  id: number | null
  isDefault: boolean | null
  isPaypal: boolean
  isVenmo: boolean
  minAmountForPaymentMethods: number
  animateTopUp: boolean
  minAmount: number
  showPaypalMsg: boolean
  checkout: CheckoutParamModel | null
  canTopUp: boolean
  bidPrice: number
  view: string
  articleTitle: string
  articleImageUrl: string
  paymentMethodsLoaded: boolean
}

const urlParams = getURlParams()
const initial_state: State = {
  balance: null,
  payMethodsNumber: null,
  popularMethods: [],
  bankMethods: [],
  cardMethods: [],
  googlePayMethod: [],
  applePayMethod: [],
  walletMethods: [],
  extraPopularMethods: [],
  paymentOption: '',
  topUpAmount: 0,
  currency: null,
  id: null,
  isDefault: null,
  paypalMethod: [],
  venmoMethod: [],
  isPaypal: false,
  isVenmo: false,
  minAmountForPaymentMethods: 0,
  animateTopUp: false,
  minAmount: 0,
  showPaypalMsg: false,
  checkout: null,
  canTopUp: true,
  bidPrice: 0,
  view:
    urlParams?.landingPage === TopupViews.PaymentOptions
      ? TopupViews.Checkout
      : urlParams?.landingPage,
  articleTitle: '',
  articleImageUrl: '',
  paymentMethodsLoaded: false,
}
const topUp = (state = initial_state, action: any) => {
  switch (action.type) {
    case UPDATE_BALANCE:
      return { ...state, balance: action.balance }

    case UPDATE_PAYMENT_METHODS:
      return {
        ...state,
        payMethodsNumber: action.payMethodsNumber,
        popularMethods: action.popularMethods,
        bankMethods: action.bankMethods,
        cardMethods: action.cardMethods,
        walletMethods: action.walletMethods,
        googlePayMethod: action.googlePayMethod,
        applePayMethod: action.applePayMethod,
        paypalMethod: action.paypalMethod,
        venmoMethod: action.venmoMethod,
        isPaypal: action.isPaypal,
        isVenmo: action.isVenmo,
        minAmountForPaymentMethods: action.minAmountForPaymentMethods,//Math.ceil(action.minAmountForPaymentMethods),
        paymentMethodsLoaded: true,
      }

    case UPDATE_POPULAR_METHODS:
      return {
        ...state,
        popularMethods: action.popularMethods,
      }
    case UPDATE_BID_PRICE:
      return {
        ...state,
        bidPrice: action.bidPrice,
      }

    case UPDATE_EXTRA_POPULAR_METHODS:
      return {
        ...state,
        extraPopularMethods: action.extraPopularMethods,
      }

    case MORE_PAYMENT_OPTION:
      return { ...state, paymentOption: action.paymentOption }

    case UPDATE_TOPUP_AMOUNT:
      return { ...state, topUpAmount: action.amount }

    case UPDATE_CURRENCY_DATA:
      return {
        ...state,
        currency: action.currency,
        id: action.id,
        isDefault: action.isDefault,
        minAmount: action.minAmount,
      }

    case ANIMATE_TOPUP:
      return {
        ...state,
        animateTopUp: action.status,
      }

    case ADD_PAYPAL_MSG:
      return { ...state, showPaypalMsg: action.trigger }

    case UPDATE_CHECKOUT_INFO:
      return {
        ...state,
        checkout: action.checkout,
      }
    case CAN_TOP_UP:
      return {
        ...state,
        canTopUp: action.value,
      }

    case UPDATE_ARTICLE_TITLE:
      return {
        ...state,
        articleTitle: action.payload,
      }
    case UPDATE_ARTICLE_IMAGE_URL:
      return {
        ...state,
        articleImageUrl: action.payload,
      }

    case UPDATE_CHECKOUT_VIEW:
      const urlParams = getURlParams()
      setURLParams({ ...urlParams, landingPage: action.payload })

      return {
        ...state,
        view: action.payload,
      }

    default:
      return state
  }
}

export default topUp
