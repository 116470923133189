import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { gsap, CSSPlugin } from 'gsap'
import reportWebVitals from './reportWebVitals'
import App from './App'
import 'normalize.css'
import 'styles/main.scss'
import * as analytics from './utils/analytics'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { LocalizationProvider } from '@mui/lab'
import store from './store'
import './i18n'

analytics.initialize()

gsap.registerPlugin(CSSPlugin)
render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <App />
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
const configuration = {
  onUpdate: (registration: any) => {
    if (registration && registration.waiting) {
      registration.unregister()
      // window.location.reload();
    }
  },
}

//  serviceWorker.register(configuration);
