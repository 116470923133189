import { getURlParams } from './sessionStorageFn'

export const isNullOrUndefined = (value: any) => {
  return value === undefined || value === null || value === 'null' || value === 'undefined'
}

export const isNullOrEmptyOrUndefined = (value: any) => {
  return value === undefined || value === null || value === ''
}

export const isInsideCheckoutFlow = () => {
  const urlParams = getURlParams()
  return (
    !isNullOrUndefined(urlParams) &&
    !isNullOrUndefined(urlParams?.bidMode) &&
    !isNullOrUndefined(urlParams?.bidPrice)
  )
}
