import config from 'config'
import decodeToken from 'utils/decodeToken'
import { handleApiErrors, manualLogout } from 'utils/handleApiErrors'
import {
  getAuthToken,
  getDeviceIdentity,
  getRefreshToken,
  getOrCreateDeviceIdentity,
  setAuthToken,
  setDeviceIdentity,
  setRefreshToken,
} from 'utils/auth'
import { setURLParams } from 'utils/sessionStorageFn'
import { isNullOrUndefined } from 'utils/common'
import {
  sourceWebPaywall,
  sourceIosPaywall,
  defaultRedirect,
  sourceAndroidPaywall,
} from 'utils/redirectUrls'
import { getURlParams } from 'utils/sessionStorageFn'
import { Dispatch } from 'react'
import { QueryParams } from 'utils/login'
import { editCustomer } from 'actions'
import { DeviceDetail } from 'interfaces'
import getDeviceInfo from 'utils/getDeviceType'
import * as analytics from 'utils/analytics'
import events from 'utils/eventNames'
import toast from 'react-hot-toast'
import { AlertColor } from '@mui/material'
import { logger } from 'utils/logger'

let tokenTimeout: any
let toastId: string = 'JP238MD02'

export const getNextToken = async () => {
  const accessToken = getAuthToken()
  const refreshToken = getRefreshToken()
  const deviceKey = getDeviceIdentity()

  let decoded: any = null
  if (accessToken) {
    decoded = decodeToken(accessToken)
  }
  const email = decoded.username

  const payload = {
    deviceKey,
    email,
    refreshToken,
  }

  try {
    const ac = new AbortController()
    const signal = ac.signal
    const response = await fetch(`${config.ApiBaseUrl}/customer/nextAccessToken`, {
      signal,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
    const verifiedResponse = handleApiErrors(response)
    const json = await verifiedResponse.json()
    const { data, message, success } = json

    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')

    setAuthToken(data.accessToken)
    decoded = decodeToken(data.accessToken)
    const expires = new Date(decoded.exp * 1000).getTime()
    const timeout = expires - Date.now() - 5 * 60 * 1000

    tokenTimeout = setTimeout(getNextToken, timeout)
  } catch (e: any) {
    manualLogout()
  }
}

export const clearOutTokenTimeout = () => clearTimeout(tokenTimeout)

export const createLoginKey = async (urlParams: any) => {
  try {
    const response = await fetch(`${config.ApiBaseUrl}/customer/createLoginToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(urlParams),
    })
    const json = await response.json()
    const { data, message, success } = json

    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')

    /**
     * *IMPORTANT - Need to change in future,
     * topup source, bidMode and bidPrice is extracted from request
     * and then set again in local storage
     */
    setURLParams(
      JSON.stringify({
        ...urlParams,
        ...data,
        bidMode: urlParams.bidMode,
        bidPrice: urlParams.bidPrice,
        bidTransactionMode: urlParams.bidTransactionMode,
        ...(urlParams?.topupSource && { topupSource: urlParams.topupSource }),
      })
    )
    analytics.track('login_token_created')
    return data
  } catch (e: any) {
    throw e
  }
}

export const processBidPostLogin = async (
  deviceKey: string,
  loginToken: string,
  fewCentsBidId: any,
  askPrice: any,
  bidTransactionMode: any,
  ampReaderId: string
) => {
  try {
    const payload = {
      deviceKey,
      loginToken,
      fewCentsBidId,
      askPrice,
      ampReaderId,
      bidTransactionMode,
    }
    const accessToken = getAuthToken()
    const response = await fetch(`${config.ApiBaseUrlV2}/fbid/processBidPostLogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })

    const verifiedResponse = handleApiErrors(response)
    const json = await verifiedResponse.json()
    const { data, message, success, statusCode } = json

    if (!success || (success && statusCode != 200)) {
      analytics.track(events.process_bid_post_login_fail, { message: message })
      throw new Error(message ?? 'Something went wrong. failed to process request')
    }
    logger.log(JSON.stringify(data, null, 2))
    return data.fewCentsBidPostLoginNextAction
  } catch (e: any) {
    analytics.track(events.process_bid_post_login_fail, { message: e.message })
    manualLogout()
  }
}

export const redirectByUrl = async (history: any, from: any, customer: any) => {
  const urlParams = getURlParams()
  if (
    (urlParams.loginSource === 'paywall' ||
      urlParams.loginSource === 'amp_paywall' ||
      urlParams?.topupSource) &&
    !isNullOrUndefined(urlParams?.loginKey)
  ) {
    await sourceWebPaywall(urlParams, customer, history)
  } else if (urlParams.loginSource === 'ios_paywall') {
    await sourceIosPaywall(urlParams, customer)
  } else if (urlParams.loginSource === 'android_paywall') {
    await sourceAndroidPaywall(urlParams, customer)
  } else {
    await defaultRedirect(urlParams, history, from)
  }
}

export const socialLoginMethod = async (
  userInfo: QueryParams | null,
  dispatch: Dispatch<any>,
  history?: any,
  from?: any
): Promise<any> => {
  const urlParams = getURlParams()

  let device: DeviceDetail = {
    os: null,
    userAgent: null,
    browser: null,
    deviceKey: null,
    type: null,
    osVersion: null,
    modelType: null,
    browserVersion: undefined,
  }

  if (window.navigator) {
    const { userAgent, platform } = window.navigator
    device = getDeviceInfo(userAgent)
    device.deviceKey = getOrCreateDeviceIdentity()
  }

  try {
    const loginKeyDetailsData = await createLoginKey(urlParams)
    const payload = {
      ...userInfo,
      ...device,
      role: 'member',
      currency: 'SGD',
      loginKey: loginKeyDetailsData?.loginKey,
      sourcePublisherId: loginKeyDetailsData?.loginPublisherId
        ? loginKeyDetailsData?.loginPublisherId
        : 0,
      loginToken: loginKeyDetailsData?.loginKey,
      loginSource: 'wallet',
    }

    const response = await fetch(`${config.ApiBaseUrl}/customer/login/social`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    const json = await response.json()
    const { data, message, success } = json

    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')

    const { customer, accessToken, refreshToken } = data

    if (!customer) throw new Error(`Error while signing in to  ${userInfo?.loginType}`)

    dispatch(editCustomer(customer))
    setAuthToken(accessToken)
    setRefreshToken(refreshToken)
    setDeviceIdentity(data.deviceKey)

    return {
      deviceKey: device.deviceKey,
      accessToken,
      refreshToken,
      ...customer,
    }
  } catch (e: any) {
    analytics.track(events.login_social_api_failed, { message: e.message })
    throw e
  }
}

export const resendEmail = (id: number) => {
  ;(async () => {
    toast.loading('Sending email...', { id: toastId })
    try {
      const response = await fetch(`${config.ApiBaseUrl}/customer/${id}/resendVerificationLink`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
      const json = await response.json()
      const { message, success } = json

      if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')

      toast.success('Please check your email for activating the account', {
        id: toastId,
      })
    } catch (e: any) {
      toast.error('Failed to send the email', { id: toastId })
    }
  })()
}

export const sendPassCode = async (
  email: string,
  history: any,
  handleSnackBar: (open: boolean, msg: string, severity: AlertColor) => void
) => {
  toast.loading('Sending code...', { id: toastId })
  try {
    const response = await fetch(`${config.ApiBaseUrl}/customer/${email}/forgotPassword`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    })
    const json = await response.json()
    const { message, success } = json

    if (!success) {
      toast.dismiss(toastId)
      handleSnackBar(true, message ?? 'Something went wrong. failed to process request', 'error')
      return false
    }

    toast.success('Please check your email for code', {
      id: toastId,
    })

    handleSnackBar(true, 'Please check your email for code', 'success')

    history.push({ pathname: '/reset-password' })
  } catch (e: any) {
    toast.dismiss(toastId)
    handleSnackBar(true, 'Failed to send the code', 'error')
    return false
  }
}

export const resetPassword = async (
  email: string,
  newPassword: string,
  resetCode: string,
  history: any,
  handleSnackBar: (open: boolean, msg: string, severity: AlertColor) => void
) => {
  const payload = {
    email,
    newPassword,
    resetCode,
  }

  toast.loading('Changing password...', { id: toastId })
  try {
    const response = await fetch(`${config.ApiBaseUrl}/customer/resetPassword`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    const json = await response.json()
    const { message, success } = json

    if (!success) {
      toast.dismiss(toastId)
      handleSnackBar(true, message ?? 'Something went wrong. failed to process request', 'error')
      return false
    }

    toast.dismiss(toastId)
    handleSnackBar(true, 'Password Changed', 'success')

    history.push({ pathname: '/login' })
  } catch (e: any) {
    toast.dismiss(toastId)
    handleSnackBar(true, 'Password Changed', 'error')
    return false
  }
}
