import { useEffect } from 'react'
import decodeToken from 'utils/decodeToken'
import { getAuthToken, removeAuthToken } from 'utils/auth'
import { getNextToken } from 'containers/Login/Utils'

let tokenTimeout: any

const useTokenExpiryCheck = () => {
  const token = getAuthToken()
  let decoded: any = null
  if (token) {
    decoded = decodeToken(token)
    if (decoded.username.includes('fc_guest_')) {
      removeAuthToken()
      decoded = null
    }
  }

  useEffect(() => {
    if (decoded) {
      const expires = new Date(decoded.exp * 1000).getTime()
      // const timeout = expires - Date.now() - 59 * 60 * 1000 - 45 * 1000;
      const timeout = expires - Date.now()

      if (timeout > 0) tokenTimeout = setTimeout(getNextToken, timeout * 0.95)
    }

    return () => clearTimeout(tokenTimeout)
  }, [])

  return decoded
}

export default useTokenExpiryCheck
