import {
  EDIT_CUSTOMER,
  GUEST_EMAIL,
  UPDATE_TOP_PAYMENT_METHODS,
  RESET_USER_STATE,
} from 'action-types'

const initial_state = {
  customer: null,
  guestEmail: null,
  topPaymentMethods: [],
  paymentMethodsLoaded: false,
}

const user = (state = initial_state, action: any) => {
  switch (action.type) {
    case EDIT_CUSTOMER:
      return { ...state, customer: action.customer, paymentMethodsLoaded: false }
    case GUEST_EMAIL:
      return { ...state, guestEmail: action.email }
    case UPDATE_TOP_PAYMENT_METHODS:
      return { ...state, topPaymentMethods: action.payload, paymentMethodsLoaded: true }
    case RESET_USER_STATE:
      return initial_state
    default:
      return state
  }
}

export default user
