import { createTheme } from '@mui/material/styles'

const breakpoints: any = {
  values: {
    xs: 0,
    fold: 280,
    sm: 540,
    tb: 720,
    tb1: 768,
    tb2: 820,
    tb3: 1024,
    lg: 1280,
    xl: 1920,
  },
}
export const theme = createTheme({
  palette: {
    primary: {
      main: '#08289a',
    },
  },
  breakpoints,
  typography: {
    body1: {
      fontSize: '0.875rem !important',
    },
    h4: {
      fontSize: '1.9rem !important',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem !important',
    },

    h6: {
      fontSize: '1.1rem !important',
    },
  },
})
