import jwt_decode from 'jwt-decode'
import { removeAuthToken, removeRefreshToken } from './auth'

const decodeToken = (token: string) => {
  try {
    const decoded: any = jwt_decode(token)
    return decoded
  } catch {
    removeAuthToken()
    removeRefreshToken()
    return undefined
  }
}

export default decodeToken
