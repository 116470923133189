// import { DeviceDetail } from 'interfaces'
// import { getOrCreateDeviceIdentity, getOrCreateFCAnonymousID } from 'utils/auth'
// import getDeviceInfo from 'utils/getDeviceType'
// import { getURlParams } from 'utils/sessionStorageFn'
// import config from 'config'
// import store from 'store'
// const Analytics: any = require('@segment/analytics.js-core/build/analytics')
// const SegmentIntegration: any = require('@segment/analytics.js-integration-segmentio')

declare global {
  interface Window {
    analytics: any
  }
}

// const analytics = new Analytics()
// analytics.use(SegmentIntegration)
// let device: DeviceDetail = getDeviceInfo(window.navigator.userAgent)
// let defaultProperties = {
//   component: 'wallet',
//   componentVersion: '6',
//   deviceKey: getOrCreateDeviceIdentity(),
//   fcAnonymousID: getOrCreateFCAnonymousID(),
//   os: device.os,
//   osVersion: device.osVersion,
//   browser: device.browser,
//   deviceType: device.type,
//   model: device.modelType,
//   previousEvent: '',
// }
// const integrationSettings = {
//   'Segment.io': {
//     apiKey: config.SegmentWriteKey,
//     retryQueue: true,
//     addBundledMetadata: true,
//     unbundledIntegrations: ['Amplitude'],
//   },
// }

export function initialize() {
  // if (config.SegmentWriteKey) {
  //   analytics.initialize(integrationSettings)
  //   analytics.setAnonymousId(config.SegmentAnonymousId)
  // }
}

export function track(...args: any[]) {
  // const urlParams = getURlParams()
  // const customer = store.getState().user.customer
  // if (urlParams) {
  //   defaultProperties = Object.assign({ urlParams: urlParams }, defaultProperties)
  // }
  // if (customer) {
  //   delete customer['accessCode']
  //   delete customer['accessToken']
  //   delete customer['refreshToken']
  //   delete customer['isEncrypted']
  //   defaultProperties = Object.assign({ customer: customer }, defaultProperties)
  // }
  // /** Assign default properties to all events */
  // if (args[1]) {
  //   Object.assign(args[1], defaultProperties)
  //   if (args[1].fewCentsBid?.customerInfo) {
  //     /** Filter out customerInfo as it contains sensitive data */
  //     delete args[1].fewCentsBid.customerInfo
  //   }
  // } else {
  //   args[1] = { ...defaultProperties }
  // }
  // if (config.SegmentWriteKey) {
  //   analytics.track(...args)
  //   defaultProperties.previousEvent = args[0]
  // }
}

// export function identify(...args: any[]) {
//   if (config.SegmentWriteKey) {
//     analytics.identify(...args)
//   }
// }
