export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'
export const UPDATE_BALANCE = 'UPDATE_BALANCE'
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS'
export const UPDATE_EXTRA_POPULAR_METHODS = 'UPDATE_EXTRA_POPULAR_METHODS'
export const MORE_PAYMENT_OPTION = 'MORE_PAYMENT_OPTION'
export const UPDATE_TOPUP_AMOUNT = 'UPDATE_TOPUP_AMOUNT'
export const UPDATE_CURRENCY_DATA = 'UPDATE_CURRENCY_DATA'
export const UPDATE_METRICS_DATA = 'UPDATE_METRICS_DATA'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const UPDATE_LANGUAGE_FILTERS = 'UPDATE_LANGUAGE_FILTERS'
export const DISPLAY_RESULTS = 'DISPLAY_RESULTS'
export const EDIT_EMAIL_PASSWORD = 'EDIT_EMAIL_PASSWORD'
export const ANIMATE_TOPUP = 'ANIMATE_TOPUP'
export const ADD_FILTER = 'ADD_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const SET_FILTER_ERROR = 'SET_FILTER_ERROR'
export const ADD_PAYPAL_MSG = 'ADD_PAYPAL_MSG'
export const UPDATE_DASHBOARD_FILTER = 'UPDATE_DASHBOARD_FILTER'
export const EDIT_PRIVACY_POLICY_CHECK = 'EDIT_PRIVACY_POLICY_CHECK'
export const UPDATE_CHECKOUT_INFO = 'UPDATE_CHECKOUT_INFO'
export const DISPLAY_SIZE_CHANGE = 'DISPLAY_SIZE_CHANGE'
export const CAN_TOP_UP = 'CAN_TOP_UP'
export const UPDATE_POPULAR_METHODS = 'UPDATE_POPULAR_METHODS'
export const LOADED_DIGITAL_ASSET = 'LOADED_DIGITAL_ASSET'
export const UPDATE_EXPAND = 'UPDATE_EXPAND'
export const UPDATE_REWARDS = 'UPDATE_REWARDS'
export const UPDATE_TOTAL_CUSTOMER_REWARDS = 'UPDATE_TOTAL_CUSTOMER_REWARDS'
export const UPDATE_LEAD_FORM = 'UPDATE_LEAD_FORM'
export const GUEST_EMAIL = 'GUEST_EMAIL'
export const UPDATE_APPlE_LOGIN_DETAILS = 'UPDATE_APPlE_LOGIN_DETAILS'
export const UPDATE_BID_PRICE = 'UPDATE_BID_PRICE'
export const UPDATE_TOP_PAYMENT_METHODS = 'UPDATE_TOP_PAYMENT_METHODS'
export const UPDATE_CHECKOUT_VIEW = 'UPDATE_CHECKOUT_VIEW'
export const UPDATE_ARTICLE_TITLE = 'UPDATE_ARTICLE_TITLE'
export const UPDATE_ARTICLE_IMAGE_URL = 'UPDATE_ARTICLE_IMAGE_URL'
export const QUESTION_ANSWER_CHANGED = 'QUESTION_ANSWER_CHANGED'
export const SET_SHOW_MORE_PAYMENT_OPTIONS = 'SET_SHOW_MORE_PAYMENT_OPTIONS'
export const SET_SHOW_MORE_REWARDS = 'SET_SHOW_MORE_REWARDS'
export const RESET_USER_STATE = 'RESET_USER_STATE'
export const SET_FILTER_OPEN_STATE = 'SET_FILTER_OPEN_STATE'
export const UNSUBSCRIBE_WALLET = 'UNSUBSCRIBE_WALLET'
export const TRACK_CHECKOUT = 'TRACK_CHECKOUT'
