const eventNames = {
  continue_as_sim_email: 'continue_as_sim_email',
  login_failed: 'login_failed',
  login_initialized: 'login_initialized',
  login_redirect_initialized: 'login_redirect_initialized',
  login_redirect_completed: 'login_redirect_completed',
  login_success: 'login_success',
  sim_login_window_closed: 'sim_login_window_closed',
  switch_account_clicked: 'switch_account_clicked',
  updated_wallet_currency: 'updated_wallet_currency',
  login_page_loaded: 'login_page_loaded',
  login_back_content_clicked: 'login_back_content_clicked',
  login_publisher_logo: 'login_publisher_logo',
  login_know_fewcents_clicked: 'login_know_fewcents_clicked',
  login_secure_payment: 'login_secure_payment',
  create_account_clicked: 'create_account_clicked',
  terms_checkbox: 'terms_checkbox',
  forget_password_clicked: 'forget_password_clicked',
  login_window_closed: 'login_window_closed',
  guest_checkout_clicked: 'guest_checkout_clicked',
  continue_login: 'continue_login',
  checkout_amount_changed: 'checkout_amount_changed',
  checkout_amount_decreased: 'checkout_amount_decreased',
  checkout_amount_increased: 'checkout_amount_increased',
  checkout_back_content_clicked: 'checkout_back_content_clicked',
  checkout_page_loaded: 'checkout_page_loaded',
  checkout_window_closed: 'checkout_window_closed',
  checkout_publisher_logo: 'checkout_publisher_logo',
  checkout_know_fewcents_clicked: 'checkout_know_fewcents_clicked',
  checkout_usp: 'checkout_usp',
  checkout_switch_account_clciked: 'checkout_switch_account_clciked',
  checkout_know_amount_clicked: 'checkout_know_amount_clicked',
  checkout_amount: 'checkout_amount',
  global_payment_methods: 'global_payment_methods',
  favourite_payment_methods: 'favourite_payment_methods',
  more_payment_option_click: 'more_payment_option_click',
  paypal_payment_shown: 'paypal_payment_shown',
  paypal_checkout_initiated: 'paypal_checkout_initiated',
  paypal_checkout_failed: 'paypal_checkout_failed',
  paypal_checkout_cancelled: 'paypal_checkout_cancelled',
  gpay_payment_shown: 'gpay_payment_shown',
  gpay_checkout_initiated: 'gpay_checkout_initiated',
  gpay_checkout_cancelled: 'gpay_checkout_cancelled',
  applepay_payment_shown: 'applepay_payment_shown',
  applepay_checkout_initiated: 'applepay_checkout_initiated',
  applepay_checkout_cancelled: 'applepay_checkout_cancelled',
  checkout_initiated: 'checkout_initiated',
  checkout_canceled: 'checkout_canceled',
  checkout_payment_response_page_click_redirect: 'checkout_payment_response_page_click_redirect',
  checkout_payment_response_page_refresh_redirect:
    'checkout_payment_response_page_refresh_redirect',
  checkout_request_failed: 'checkout_request_failed',
  checkout_successful: 'checkout_successful',
  payment_failed: 'payment_failed',
  payment_success: 'payment_success',
  guest_email_entered: 'guest_email_entered',
  guest_info_card_clicked: 'guest_info_card_clicked',
  guest_payment_initiated: 'guest_payment_initiated',
  guest_payment_success: 'guest_payment_success',
  guest_payment_failed: 'guest_payment_failed',
  constructor_called: 'constructor_called',
  bot_detected: 'bot_detected',
  terms_link_clicked: 'terms_link_clicked',
  contact_link_clicked: 'contact_link_clicked',
  privacy_link_clicked: 'privacy_link_clicked',
  welcome_email_loaded: 'welcome_email_loaded',
  welcome_email_publisher_list: 'welcome_email_publisher_list',
  welcome_email_publisher_click: 'welcome_email_publisher_click',
  wallet_link_click: 'wallet_link_click',
  rapyd_link_click: 'rapyd_link_click',
  paypal_link_click: 'paypal_link_click',
  email_link_click: 'email_link_click',
  otp_email_loaded: 'otp_email_loaded',
  fc_logo_click: 'fc_logo_click',
  paypal_payment_email_loaded: 'paypal_payment_email_loaded',
  transaction_id_click: 'transaction_id_click',
  buyer_email_link_click: 'buyer_email_link_click',
  fewcents_email_link_click: 'fewcents_email_link_click',
  help_link_click: 'help_link_click',
  security_link_click: 'security_link_click',
  apps_link_click: 'apps_link_click',
  phishing_link_click: 'phishing_link_click',
  learn_more_link_click: 'learn_more_link_click',
  paywall_checkout_email_loaded: 'paywall_checkout_email_loaded',
  paywall_checkout_email_article: 'paywall_checkout_email_article',
  paywall_checkout_email_article_click: 'paywall_checkout_email_article_click',
  tipjar_checkout_email_loaded: 'tipjar_checkout_email_loaded',
  tipjar_checkout_email_article: 'tipjar_checkout_email_article',
  tipjar_checkout_email_article_click: 'tipjar_checkout_email_article_click',
  user_email_link_click: 'user_email_link_click',
  wallet_checkout_email_loaded: 'wallet_checkout_email_loaded',
  wallet_checkout_email_publisher_list: 'wallet_checkout_email_publisher_list',
  wallet_checkout_publisher_click: 'wallet_checkout_publisher_click',
  continue_as_sim_cancel_click: 'continue_as_sim_cancel_click',
  continue_as_account_switch_click: 'continue_as_account_switch_click',
  know_more_about_fewcents_click: 'know_more_about_fewcents_click',
  set_password_success: 'set_password_success',
  guest_back_button_clicked: 'guest_back_button_clicked',
  checkout_switch_account_click: 'checkout_switch_account_click',
  forgot_password_clicked: 'forgot_password_clicked',
  paypal_checkout_error: 'paypal_checkout_error',
  redirecting_to_paywall_post_paypal: 'redirecting_to_paywall_post_paypal',
  get_facebook_details_api_issue: 'get_facebook_details_api_issue',
  access_token_not_found: 'access_token_not_found',
  id_token_not_found: 'id_token_not_found',
  payment_fail: 'payment_fail',
  process_bid_post_login_fail: 'process_bid_post_login_fail',
  login_social_api_failed: 'login_social_api_failed',
  browser_closed: 'browser_closed',
  claim_reward_clicked: 'claim_reward_clicked',
  use_reward_clicked: 'use_reward_clicked',
  reward_consent_clicked: 'reward_consent_clicked',
  more_rewards_clicked: 'more_rewards_clicked',
  reward_claimed_via_checkout: 'reward_claimed_via_checkout',
  reward_claimed_via_rewards: 'reward_claimed_via_rewards',
  rewards_back_button_clicked: 'rewards_back_button_clicked',
  payment_options_back_button_clicked: 'payment_options_back_button_clicked',
  checkout_reward_shown: 'checkout_reward_shown',
  rewards_shown: 'rewards_shown',
  lead_form_submission_successful: 'lead_form_submission_successful',
  lead_form_submission_failed: 'lead_form_submission_failed',
}

export default eventNames
