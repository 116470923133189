import { FC, useEffect, useState } from 'react'
import config from 'config'
import Logo from 'assets/svgs/blue-logo-transparent-bg.svg'
import './RapydPage.scss'
import * as analytics from 'utils/analytics'
import {
  postMessageToPaywall,
  acceptBidPostCheckout,
  postProcessingCompleteMessageToPaywall,
} from 'components/TopUp/Utils/helper'
import { useLocation } from 'react-router'
import { UpdateCheckoutTransaction } from 'interfaces'
import updateTransaction from 'components/TopUp/Utils/updateTransaction'
import { getURlParams, setURLParams } from 'utils/sessionStorageFn'
import { removeAuthToken } from 'utils/auth'
import events from 'utils/eventNames'
import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { isInsideCheckoutFlow } from 'utils/common'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import { green, grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import GppGoodIcon from '@mui/icons-material/GppGood'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { theme } from 'theme'
import React from 'react'
import SuccessTimer from 'components/TopUp/SuccessTimer'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { useTranslation } from 'react-i18next'

const isCheckoutFlow = isInsideCheckoutFlow()
const BorderLinearProgress = styled(LinearProgress)(({ theme: any }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? 'rgb(35, 122, 255)' : '#308fe8',
  },
}))

export enum CallbackStatus {
  PaymentError,
  PaymentSuccess,
  CheckoutCanceled,
  CheckoutCompleted,
}

interface RapydStatusProps {
  event: String
  status: CallbackStatus
}

const RapydStatus: FC<RapydStatusProps> = ({ event, status }) => {
  const { t, i18n } = useTranslation()
  const urlParams = getURlParams()
  const secondsBeforeAutoRedirect = status === CallbackStatus.CheckoutCompleted ? 10 : 5
  const [redirect, setRedirect] = useState(false)
  const location = useLocation()
  let isGuestUser = urlParams?.loginMethod === 'guest'
  const qs = new URLSearchParams(location.search)
  const [progress, setProgress] = React.useState(10)

  useEffect(() => {
    updateRequest()
    executePaywallBackgroundProcessing()
  }, [])

  useEffect(() => {
    if (redirect) {
      redirectTo()
    }
  }, [redirect])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
       (prevProgress >= 100 ? 10 : prevProgress + 10
        ))
      console.log("checkout status page - inside timer")
    }, 500)
    setTimeout(() => {
      console.log("checkout status page - timing out now")
      setRedirect(true)
    }, 5000);
    return () => {
      console.log("checkout status page - clearing timer now")
      clearInterval(timer)
    }
  }, [])

  const updateRequest = () => {
    let id = qs.get('id')
    let status = getStatus()
    if (status) {
      let Payload: UpdateCheckoutTransaction = {
        customerId: id,
        bidId: urlParams?.loginBidId,
        status: status,
      }
      updateTransaction(Payload)
    }
  }
  const redirectTo = async (click = false) => {
    console.log("checkout status page - redirecting now")
    const qs = new URLSearchParams(location.search)
    let id = qs.get('id')
    if (click) {
      analytics.track(events.checkout_payment_response_page_click_redirect, {
        gateway: qs.get('gateway'),
      })
    } else {
      analytics.track(events.checkout_payment_response_page_refresh_redirect, {
        gateway: qs.get('gateway'),
      })
    }
    if (isCheckoutFlow) {
      postProcessingCompleteMessageToPaywall(urlParams)
    } else {
      window.location.href = urlParams
        ? `${urlParams.loginRedirectUrl}`
        : `${config.Domain}/dashboard`
    }
    setURLParams(
      JSON.stringify({
        ...urlParams,
        loginRedirectUrl: `${config.Domain}/dashboard`,
        loginFailTopupRedirectUrl: `${config.Domain}/dashboard`,
      })
    )
  }

  const getStatus = () => {
    if (status == CallbackStatus.CheckoutCanceled) {
      return 'FC_CANCELLED'
    } else if (status == CallbackStatus.PaymentError) {
      return 'FC_FAILED'
    } else return null
  }
  const executePaywallBackgroundProcessing = async () => {
    const qs = new URLSearchParams(location.search)
    if (isCheckoutFlow) {
      if (status === CallbackStatus.PaymentSuccess || status === CallbackStatus.CheckoutCompleted) {
        const bidToBeUnlocked = status === CallbackStatus.PaymentSuccess

        // call new API to accept bid from Wallet
        analytics.track(isGuestUser ? 'guest_payment_success' : 'payment_success', {
          status: status,
          gateway: qs.get('gateway'),
          country: qs.get('country'),
          currency: qs.get('currency'),
          amount: qs.get('amount'),
        })
        await acceptBidPostCheckout(urlParams, bidToBeUnlocked)
        postMessageToPaywall(urlParams)
      } else {
        if (isGuestUser) {
          removeAuthToken()
        }
        analytics.track(isGuestUser ? events.guest_payment_failed : events.payment_fail, {
          status: status,
          gateway: qs.get('gateway'),
          country: qs.get('country'),
          currency: qs.get('currency'),
          amount: qs.get('amount'),
        })
        postMessageToPaywall(urlParams)
      }
    }
  }

  const getTitleClass = () => {
    if (status === CallbackStatus.PaymentSuccess)
      return 'RapydPage__heading RapydPage__heading--success'
    else if (status === CallbackStatus.CheckoutCompleted)
      return 'RapydPage__heading RapydPage__heading--pending'
    else return 'RapydPage__heading RapydPage__heading--error'
  }

  const getSubTitleClass = () => {
    if (status === CallbackStatus.PaymentSuccess)
      return 'RapydPage__heading-description RapydPage__heading-description--success'
    else if (status === CallbackStatus.CheckoutCompleted)
      return 'RapydPage__heading-description RapydPage__heading-description--pending'
    else return 'RapydPage__heading-description RapydPage__heading-description--error'
  }

  const getTitleText = () => {
    if (status === CallbackStatus.PaymentSuccess) {
      return t('money_checkout_success')
    } else if (status === CallbackStatus.PaymentError) {
      return t('money_checkout_failed')
    } else if (status === CallbackStatus.CheckoutCompleted) {
      return t('money_checkout_completed')
    } else if (status === CallbackStatus.CheckoutCanceled) {
      return t('money_checkout_canceled')
    } else {
      return t('money_checkout_processed')
    }
  }

  const getSubTitleText = () => {
    if (isCheckoutFlow && status === CallbackStatus.CheckoutCompleted) {
      return t('money_checkout_completed_subtitle_checkoutflow')
    }
    if (urlParams?.loginMethod == 'guest' && status == CallbackStatus.PaymentSuccess) {
      return t('money_checkout_success_subtitle_guest')
    } else {
      if (status === CallbackStatus.PaymentSuccess) {
        return t('money_checkout_success_subtitle')
      } else if (status === CallbackStatus.PaymentError) {
        return t('money_checkout_failed_subtitle')
      } else if (status === CallbackStatus.CheckoutCompleted) {
        return t('money_checkout_completed_subtitle')
      } else if (status === CallbackStatus.CheckoutCanceled) {
        return t('money_checkout_canceled_subtitle')
      } else {
        return t('money_checkout_processed_subtitie')
      }
    }
  }

  const extraStep = t('money_checkout_completed_extrastep')
  const extraStepButton = t('money_checkout_completed_extrastep_button')
  const finalText = urlParams &&
                    urlParams.loginRedirectUrl &&
                    urlParams.loginRedirectUrl.includes('dashboard')
                      ? t('money_checkout_fewcents_account')
                      : urlParams?.publisherName
                      
  const language = i18n.language

  return (
    <>
      <Grid container spacing={2} className="RapydPage">
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={4}>
          <Card className="RapydPage__card">
            <CardMedia
              className={
                status === CallbackStatus.PaymentSuccess
                  ? 'RapydPage__cardMediaBgSuccess'
                  : status === CallbackStatus.PaymentError
                  ? 'RapydPage__cardMediaBgError'
                  : status === CallbackStatus.CheckoutCompleted
                  ? 'RapydPage__cardMediaBgWarning'
                  : 'RapydPage__cardMediaBgError'
              }
            >
              {status === CallbackStatus.PaymentSuccess ? (
                <CheckCircleRoundedIcon
                  className="FormStatus__img"
                  sx={{ color: grey[50], fontSize: 100 }}
                ></CheckCircleRoundedIcon>
              ) : status === CallbackStatus.PaymentError ? (
                <ErrorRoundedIcon
                  className="FormStatus__img"
                  sx={{ color: grey[50], fontSize: 100 }}
                ></ErrorRoundedIcon>
              ) : status === CallbackStatus.CheckoutCompleted ? (
                <AccessTimeRoundedIcon
                  className="FormStatus__img"
                  sx={{ color: grey[50], fontSize: 100 }}
                ></AccessTimeRoundedIcon>
              ) : (
                <CancelOutlinedIcon
                  className="FormStatus__img"
                  sx={{ color: grey[50], fontSize: 100 }}
                ></CancelOutlinedIcon>
              )}
            </CardMedia>

            <CardContent>
              <div className="RapydPage__text_center">
                <Typography variant="h4" className={getTitleClass()}>
                  {getTitleText()}
                </Typography>
                <Typography variant="subtitle2" className="RapydPage__mt-10">
                  {getSubTitleText()}
                </Typography>
              </div>
              {status === CallbackStatus.CheckoutCompleted ? (
                <div className="RapydPage__offline-method">
                  <div className="RapydPage__offline-method-text">
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={9}>
                        <span>
                          {extraStep}
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <button
                          onClick={() => {
                            window.history.back()
                          }}
                          className="RapydPage__offline-method--back-button"
                        >
                          {extraStepButton}
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={8}>
                  <BorderLinearProgress value={progress} variant="determinate" className="mt-2" />
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
              </Grid>
              <div className="RapydPage__redirect-text">
                {
                  language == 'ja' ?
                  <p className="RapydPage__text_para">
                  <label
                    onClick={() => redirectTo(true)}
                    className="RapydPage__redirect-link"
                    title={
                      urlParams ? `${urlParams.loginRedirectUrl}` : `${config.Domain}/dashboard`
                    }
                  >
                    {finalText}
                  </label>
                  {t('login_redirectingYouBackTo')}
                  <Grid container spacing={2} className="RapydPage__mt-container">
                    <Grid item xs={6}>
                      <small className="RapydPage__secure_text"> {t('money_checkout_100%_secure')}</small>
                      <small className="RapydPage__secure_text">
                        <GppGoodIcon sx={{ color: green[500] }}></GppGoodIcon>
                      </small>
                    </Grid>
                    <Grid item xs={6}>
                      <small className="RapydPage__secure_img">
                        <img alt="Fewcents-logo" className="RapydPage__img" src={Logo} />{' '}
                      </small>
                    </Grid>
                  </Grid>
                </p>
                :
                <p className="RapydPage__text_para">
                  {t('login_redirectingYouBackTo')}
                  <label
                    onClick={() => redirectTo(true)}
                    className="RapydPage__redirect-link"
                    title={
                      urlParams ? `${urlParams.loginRedirectUrl}` : `${config.Domain}/dashboard`
                    }
                  >
                    {finalText}
                  </label>
                  <Grid container spacing={2} className="RapydPage__mt-container">
                    <Grid item xs={6}>
                      <small className="RapydPage__secure_text"> {t('money_checkout_100%_secure')}</small>
                      <small className="RapydPage__secure_text">
                        <GppGoodIcon sx={{ color: green[500] }}></GppGoodIcon>
                      </small>
                    </Grid>
                    <Grid item xs={6}>
                      <small className="RapydPage__secure_img">
                        <img alt="Fewcents-logo" className="RapydPage__img" src={Logo} />{' '}
                      </small>
                    </Grid>
                  </Grid>
                </p>
                }
                
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
        </Grid>
      </Grid>
    </>
  )
}

export default RapydStatus
