import {
  ADD_FILTER,
  CLEAR_FILTER,
  DISPLAY_RESULTS,
  REMOVE_FILTER,
  SET_FILTER_ERROR,
  SET_FILTER_OPEN_STATE,
  UPDATE_FILTERS,
  UPDATE_LANGUAGE_FILTERS,
} from 'action-types'

interface ArticlesState {
  countries: Array<any>
  publishersByCountries: Array<any>
  publishers: Array<any>
  allPublishersDropdown: any
  filters: any
  isResult: false
  filterError: ''
  languages: []
  isFilterOpen: false
}

const initial_state: ArticlesState = {
  countries: [],
  publishersByCountries: [],
  publishers: [],
  allPublishersDropdown: null,
  filters: {},
  isResult: false,
  filterError: '',
  languages: [],
  isFilterOpen: false,
}

const articles = (state = initial_state, action: any) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return {
        ...state,
        countries: action.countries,
        publishersByCountries: action.publishersByCountries,
        publishers: action.publishers,
        allPublishersDropdown: action.allPublishersDropdown,
      }
    case UPDATE_LANGUAGE_FILTERS:
      return {
        ...state,
        languages: action.languages,
      }
    case DISPLAY_RESULTS:
      return { ...state, isResult: action.isResult }
    case ADD_FILTER:
      return { ...state, filters: { ...state.filters, ...action.filter } }
    case REMOVE_FILTER:
      const key: string = action.key
      const { [key]: _, ...newFilters }: any = state.filters
      return { ...state, filters: { ...newFilters } }
    case CLEAR_FILTER:
      return { ...state, filters: {} }
    case SET_FILTER_ERROR:
      return { ...state, filterError: action.error }
    case SET_FILTER_OPEN_STATE:
      return { ...state, isFilterOpen: action.payload }
    default:
      return state
  }
}

export default articles
