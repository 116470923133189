import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import config from 'config'
import decodeToken from 'utils/decodeToken'
import { editCustomer } from 'actions'
import { RootState } from 'reducers'
import { handleApiErrors, manualLogout } from 'utils/handleApiErrors'
import { getAuthToken } from 'utils/auth'
import { getNextToken } from 'containers/Login/Utils'
import { logger } from 'utils/logger'

export const useFetchCustomer = (): [string, boolean] => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const customer = useSelector((state: RootState) => state.user.customer)
  const accessToken = getAuthToken()

  useEffect(() => {
    let mounted = true
    const ac = new AbortController()
    const signal = ac.signal
    ;(async (signal) => {
      if (!customer && accessToken) {
        const decoded = decodeToken(accessToken)
        if (decoded.exp * 1000 <= Date.now()) {
          await getNextToken()
        }
        const newAccessToken = getAuthToken()
        const newDecoded = decodeToken(newAccessToken)

        setLoading(true)
        try {
          const response = await fetch(`${config.ApiBaseUrl}/customer/${newDecoded.username}`, {
            signal,
            headers: {
              Authorization: `Bearer ${newAccessToken}`,
            },
          })
          const verifiedResponse = handleApiErrors(response)
          const json = await verifiedResponse.json()
          const { data, message, success } = json

          if (!success)
            throw new Error(message ?? 'Something went wrong. failed to process request')

          dispatch(editCustomer(data))
        } catch (e: any) {
          if (e.message.includes('Unauthorized')) manualLogout()
          if (signal?.aborted) {
            logger.warn(e)
          }
          if (mounted) {
            setError(e.message)
            setLoading(false)
          }
        }
      }
    })(signal)

    return () => {
      mounted = false
      ac.abort()
    }
  }, [customer])

  return [error, loading]
}
