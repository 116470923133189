import { logger } from 'utils/logger'

const sourceAndroidPaywall = (urlParams: any, customer: any) => {
  try {
    const JSBridge: any = (window as any)?.JSBridge
    let data = {
      login_key: urlParams?.loginKey,
      device_key: customer?.deviceKey,
    }
    alert(`sending this data to android ${JSON.stringify(data)}`)
    JSBridge.showMessageInNative(JSON.stringify(data))
  } catch (error) {
    logger.log('Error while posting message to fewcents Android SDK')
  }
}

export default sourceAndroidPaywall
