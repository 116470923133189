import { EDIT_EMAIL_PASSWORD, EDIT_PRIVACY_POLICY_CHECK } from 'action-types'

const initial_state = {
  email: null,
  password: null,
  privacyPolicyCheck: true,
}

const auth = (state = initial_state, action: any) => {
  switch (action.type) {
    case EDIT_EMAIL_PASSWORD:
      return { ...state, email: action.email, password: action.password }
    case EDIT_PRIVACY_POLICY_CHECK:
      return { ...state, privacyPolicyCheck: action.privacyPolicyCheck }
    default:
      return state
  }
}

export default auth
