import { combineReducers } from 'redux'
import userReducer from './user'
import transactionReducer from './transaction'
import topUpReducer from './topUp'
import metricReducer from './metric'
import articlesReducer from './articles'
import authReducer from './auth'
import dashboardReducer from './dashboard'
import displayReducer from './displaySize'
import rewardsReducer from './rewards'

const rootReducer = combineReducers({
  user: userReducer,
  transaction: transactionReducer,
  topUp: topUpReducer,
  metrics: metricReducer,
  articles: articlesReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  displaySize: displayReducer,
  rewards: rewardsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
