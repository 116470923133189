import config from 'config'
import { getURlParams, setURLParams } from './sessionStorageFn'

const addLoginMethod = (method: string) => {
  let urlParams = getURlParams()
  if (urlParams) {
    urlParams = JSON.stringify({ ...urlParams, loginMethod: method })
    setURLParams(urlParams)
    return urlParams
  } else {
    urlParams = JSON.stringify({
      loginSource: 'wallet',
      loginRedirectUrl: `${config.Domain}/dashboard`,
      loginMethod: method,
    })
    setURLParams(urlParams)
    return urlParams
  }
}

export default addLoginMethod
