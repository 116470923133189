import config from 'config'
import { getURlParams, setURLParams } from 'utils/sessionStorageFn'
import jwt_decode from 'jwt-decode'
import * as analytics from './analytics'
import events from 'utils/eventNames'
import { getAuthToken, removeAuthToken } from 'utils/auth'
import { logger } from 'utils/logger'

interface OAuthParameters {
  [client_id: string]: string
  redirect_uri: string
  response_type: string
  access_type: string
  fetch_basic_profile: string
  scope: string
  include_granted_scopes: string
  state: string
  nonce: string
}

export interface QueryParams {
  name: string | null
  email: string
  accessToken: string | null
  loginType: string
  sourcePublisherId: number
}

export const googleOAuthLogin = (isUserInitialised: boolean, state: string): void => {
  const eventName = isUserInitialised ? events.login_initialized : events.login_redirect_initialized
  analytics.track(eventName, { loginSource: 'google' })
  // Google's OAuth 2.0 endpoint for requesting an access token
  const oAuth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement('form')
  form.setAttribute('method', 'GET') // Send as a GET request.
  form.setAttribute('action', oAuth2Endpoint)

  // Parameters to pass to OAuth 2.0 endpoint.
  const params: OAuthParameters = {
    client_id: config.GoogleAppId,
    redirect_uri: `${config.Domain}/login`,
    response_type: 'id_token token',
    prompt: 'select_account',
    access_type: 'online',
    fetch_basic_profile: 'true',
    scope: 'openid profile email',
    include_granted_scopes: 'true',
    state: state,
    nonce: 'abhi',
  }

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form)
  form.submit()
}

export const getAuthQueryParams = (url: string): QueryParams | null => {
  const queryString = new URLSearchParams(url)
  const urlParams = getURlParams()

  if (queryString.has('id_token')) {
    const idToken = queryString.get('id_token') ?? ''
    const decoded: any = jwt_decode(idToken)

    return {
      name: decoded.name,
      email: decoded.email,
      accessToken: idToken,
      loginType: 'google',
      sourcePublisherId: urlParams?.loginPublisherId ? urlParams?.loginPublisherId : 0,
    }
  } else {
    analytics.track(events.id_token_not_found, { url: url })
    return null
  }
}

export const facebookLogin = (isUserInitialised: boolean, state: string) => {
  const eventName = isUserInitialised ? events.login_initialized : events.login_redirect_initialized
  analytics.track(eventName, { loginSource: 'facebook' })
  const appId = config.FacebookAppId
  const uri = encodeURI(`${config.Domain}/login`)
  window.location.href = encodeURI(
    `https://www.facebook.com/v9.0/dialog/oauth?client_id=${appId}&redirect_uri=${uri}&state=${state}&response_type=token&auth_type=rerequest&scope=email`
  )
}

export const guestLogin = (urlParams: any) => {
  if (getAuthToken()) {
    removeAuthToken()
  }
}

export const getFacebookDetails = async (url: string): Promise<QueryParams | null> => {
  const queryString = new URLSearchParams(url)
  const urlParams = getURlParams()
  const state = queryString.get('state')
  logger.log('getFacebookDetails : queryString', queryString)
  logger.log('getFacebookDetails : state', state)
  logger.log('getFacebookDetails : url_paramms ', urlParams)
  logger.log('getFacebookDetails : inside first if')

  if (queryString.has('access_token')) {
    logger.log('getFacebookDetails : inside second if')
    const accessToken = queryString.get('access_token') ?? ''
    const baseUrl = 'https://graph.facebook.com/v9.0/me?'
    const finalUrl = `${baseUrl}access_token=${accessToken}&fields=${encodeURIComponent(
      'id,name,email'
    )}&format=json&method=get&pretty=0&suppress_http_code=1&transport=cors`

    try {
      const response = await fetch(finalUrl)
      const json = await response.json()
      const email = json.email || `${json.id}@facebook.fewcents.co`
      return {
        name: json.name,
        email,
        accessToken,
        loginType: 'facebook',
        sourcePublisherId: urlParams?.loginPublisherId ? urlParams?.loginPublisherId : 0,
      }
    } catch (e: any) {
      analytics.track(events.get_facebook_details_api_issue, { message: e.message })
      throw e
    }
  }
  analytics.track(events.access_token_not_found, { url: url, source: 'facebook' })
  return null
}

const AppleIdSdkUrl =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
const appleScriptId = '__appleid_auth_sdk'

const loadSDK = () => {
  return new Promise<void>((resolve, reject) => {
    const existingScript = document.getElementById(appleScriptId)
    if (existingScript) {
      resolve()
    } else {
      const script = document.createElement('script')
      script.src = AppleIdSdkUrl
      script.id = appleScriptId
      document.body.appendChild(script)
      script.onload = () => {
        resolve()
      }
      script.onerror = () => {
        reject()
      }
    }
  })
}

const appleConfig = {
  clientId: config.AppleClientId,
  redirectURI: `${config.ApiBaseUrl}/customer/login/social/apple/redirect`,
  scope: 'name email',
  state: 'state',
  nonce: 'nonce',
  usePopup: false,
}

export const appleLogin = async (isUserInitialised: boolean, history?: any): Promise<any> => {
  try {
    const winRef = window as any
    const eventName = isUserInitialised
      ? events.login_initialized
      : events.login_redirect_initialized
    analytics.track(eventName, { loginSource: 'apple' })
    await loadSDK()
    await winRef.AppleID?.auth?.init(appleConfig)
    await winRef.AppleID.auth.signIn()
  } catch (error) {
    logger.error('Apple login Failed:', error)
    const urlParams = getURlParams()
    setURLParams(
      JSON.stringify({
        ...urlParams,
        loginMethod: null,
      })
    )
    history.push({ pathname: '/login' })
  }
}
