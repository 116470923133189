import { getAuthToken } from 'utils/auth'
import config from 'config'
import { handleApiErrors } from 'utils/handleApiErrors'
import { UpdateCheckoutTransaction } from 'interfaces'
import { logger } from 'utils/logger'

const updateTransaction = async (payload: UpdateCheckoutTransaction) => {
  const accessToken = getAuthToken()
  try {
    const response = await fetch(`${config.ApiBaseUrl}/customer/checkout/updateTransaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
    const verifiedResponse = handleApiErrors(response)
    const json = await verifiedResponse.json()
    const { data, message, success } = json
    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')
  } catch (e: any) {
    logger.error('Some issue while calling the api.')
  }
}

export default updateTransaction
