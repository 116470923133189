import { FC, useEffect } from 'react'
import Logo from 'assets/svgs/blue-logo-transparent-bg.svg'
import './FormStatus.scss'
import { postMessageToPaywall } from 'components/TopUp/Utils/helper'
import SuccessTimer from 'components/TopUp/SuccessTimer'
import { getURlParams } from 'utils/sessionStorageFn'
import Typography from '@mui/material/Typography'
import { useAppSelector } from 'store'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  linearProgressClasses,
  styled,
} from '@mui/material'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { green, grey } from '@mui/material/colors'
import React from 'react'
import { theme } from 'theme'
import GppGoodIcon from '@mui/icons-material/GppGood'

const BorderLinearProgress = styled(LinearProgress)(({ theme: any }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? 'rgb(35, 122, 255)' : '#308fe8',
  },
}))

export enum FormCallbackStatus {
  Success,
  Failed,
}
interface FormStatusProps {
  event: String
  status: FormCallbackStatus
}

const FormStatus: FC<FormStatusProps> = ({ event, status }) => {
  const { t } = useTranslation()
  const leadForm = useAppSelector((state) => state.rewards?.leadForm)
  const urlParams = getURlParams()
  const secondsBeforeAutoRedirect = status === FormCallbackStatus.Success ? 10 : 5
  const [progress, setProgress] = React.useState(10)

  const getTitleText = () => {
    if (status === FormCallbackStatus.Success) {
      return t('reward_checkout_success')
    } else if (status === FormCallbackStatus.Failed) {
      return t('reward_checkout_failed')
    } else {
      return t('reward_checkout_processed')
    }
  }

  const getSubTitleText = () => {
    if (status === FormCallbackStatus.Success) {
      return t('reward_checkout_success_subtitle')
    } else if (status === FormCallbackStatus.Failed) {
      return t('reward_checkout_failed_subtitle')
    } else {
      return t('reward_checkout_processed_subtitle')
    }
  }

  const getTitleClass = () => {
    if (status === FormCallbackStatus.Success)
      return 'FormStatus__heading FormStatus__heading--success'
    else if (status === FormCallbackStatus.Failed)
      return 'FormStatus__heading FormStatus__heading--error'
  }

  const redirectTo = () => {
    if (status === FormCallbackStatus.Success) {
      try {
        if (leadForm.pixelScript) {
          eval(leadForm.pixelScript)
        }
      } catch (error) {
        console.warn('Failed to eval pixel script', error)
      }
      postMessageToPaywall(urlParams, true)
    } else {
      // TODO: should come here only in case of external lead form failure
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10))
    }, 500)
    setTimeout(() => {
      redirectTo()
    }, 5000);
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <>
      <Grid container spacing={2} className="RapydPage">
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={4}>
          {' '}
          <Card className="RapydPage__card">
            <CardMedia
              className={
                status === FormCallbackStatus.Success
                  ? 'RapydPage__cardMediaBgSuccess'
                  : 'RapydPage__cardMediaBgError'
              }
            >
              {' '}
              {status === FormCallbackStatus.Success ? (
                <CheckCircleRoundedIcon
                  className="FormStatus__img"
                  sx={{ color: grey[50], fontSize: 100 }}
                ></CheckCircleRoundedIcon>
              ) : (
                <ErrorRoundedIcon
                  className="FormStatus__img"
                  sx={{ color: grey[50], fontSize: 100 }}
                ></ErrorRoundedIcon>
              )}
            </CardMedia>

            <CardContent>
              <div className="RapydPage__text_center">
                <Typography variant="h4" className={getTitleClass()}>
                  {getTitleText()}
                </Typography>
                <Typography variant="subtitle1">{getSubTitleText()}</Typography>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={8}>
                  <BorderLinearProgress value={progress} variant="determinate" className="mt-2" />
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
              </Grid>
              <div className="FormStatus__redirect-text">
                <p className="RapydPage__text_para">
                  {t('login_redirectingYouBackTo')}
                  <label
                    onClick={() => redirectTo()}
                    className="FormStatus__redirect-link"
                    title={urlParams?.loginRedirectUrl}
                  >
                    {t('login_content')}
                  </label>
                  <Grid container spacing={2} className="RapydPage__mt-container">
                    <Grid item xs={6}>
                      <small className="RapydPage__secure_text">{t('reward_checkout_100')}</small>
                      <small className="RapydPage__secure_text">
                        <GppGoodIcon sx={{ color: green[500] }}></GppGoodIcon>
                      </small>
                    </Grid>
                    <Grid item xs={6}>
                      <small className="RapydPage__secure_img">
                        <img alt="Fewcents-logo" className="RapydPage__img" src={Logo} />{' '}
                      </small>
                    </Grid>
                  </Grid>
                </p>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
        </Grid>
      </Grid>
    </>
  )
}

export default FormStatus
