const winRef = window as any
winRef.fcLogLevel = 1 //by default just errors

export const logger = {
  trace: (message?: any, ...optionalParams: any[]) => {
    if (winRef.fcLogLevel >= 5) {
      console.trace(message, optionalParams)
    }
  },
  log: (message?: any, ...optionalParams: any[]) => {
    if (winRef.fcLogLevel >= 4) {
      console.log(message, optionalParams)
    }
  },
  info: (message?: any, ...optionalParams: any[]) => {
    if (winRef.fcLogLevel >= 3) {
      console.info(message, optionalParams)
    }
  },
  warn: (message?: any, ...optionalParams: any[]) => {
    if (winRef.fcLogLevel >= 3) {
      console.warn(message, optionalParams)
    }
  },
  error: (message?: any, ...optionalParams: any[]) => {
    if (winRef.fcLogLevel >= 1) {
      console.error(message, optionalParams)
    }
  },
}
