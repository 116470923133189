import * as analytics from 'utils/analytics'
import {
  postMessageToPaywallForCurrencyMismatchDuringCheckout,
  postMessageToPaywallForGetCustomerBid,
  postMessageToWebPaywallAfterLogin,
  postProcessingCompleteMessageToPaywall,
} from 'components/TopUp/Utils/helper'
import config from 'config'
import { getAuthToken } from 'utils/auth'
import { setURLParams } from 'utils/sessionStorageFn'
import { processBidPostLogin } from 'containers/Login/Utils'
import { logger } from 'utils/logger'
import { TopupViews } from 'reducers/topUp'

const sourceWebPaywall = async (urlParams: any, customer: any, history: any) => {
  if (
    window.opener &&
    !window.opener.closed &&
    typeof window.opener.postMessage === 'function' &&
    urlParams.uxMode === 'postMessage'
  ) {
    analytics.track('window_opener_found')
  } else {
    analytics.track('window_opener_not_found')
  }
  if (urlParams?.bidPrice && urlParams?.bidMode) {
    analytics.track('process_bid_called')
    const fewCentsBidPostLoginNextAction = await processBidPostLogin(
      customer?.deviceKey,
      urlParams?.loginKey,
      urlParams?.loginBidId,
      urlParams?.bidPrice,
      urlParams?.bidTransactionMode,
      urlParams?.ampReaderId
    )
    analytics.track('process_bid_success', { nextAction: fewCentsBidPostLoginNextAction })
    if (
      fewCentsBidPostLoginNextAction === 'redirectToTopUpThenCallGetTokenWithAcceptBid' ||
      urlParams?.landingPage === TopupViews.Rewards
    ) {
      history.replace({ pathname: '/loginCheck' })
    } else {
      if (urlParams.loginSource === 'paywall') {
        if (fewCentsBidPostLoginNextAction === 'redirectToCallCurrencyMisMatch') {
          analytics.track('currency_mismatch')
          postMessageToWebPaywallAfterLogin(urlParams, fewCentsBidPostLoginNextAction)
          postProcessingCompleteMessageToPaywall(urlParams, true)
        } else {
          if (urlParams?.loginMethod !== 'continue' && urlParams?.loginMethod !== 'sim') {
            postMessageToWebPaywallAfterLogin(urlParams, fewCentsBidPostLoginNextAction)
            postProcessingCompleteMessageToPaywall(urlParams, true)
          } else {
            setURLParams(
              JSON.stringify({
                ...urlParams,
                isContinueLogin: true,
                fewCentsBidPostLoginNextAction: fewCentsBidPostLoginNextAction,
              })
            )
            history.replace({ pathname: '/loginCheck' })
          }
        }
      } else if (
        urlParams.topupSource === 'paywall' &&
        fewCentsBidPostLoginNextAction === 'redirectToCallCurrencyMisMatch'
      ) {
        analytics.track('currency_mismatch')
        postMessageToPaywallForCurrencyMismatchDuringCheckout(urlParams)
        postProcessingCompleteMessageToPaywall(urlParams)
      } else if (
        urlParams.topupSource === 'paywall' &&
        fewCentsBidPostLoginNextAction === 'redirectToCallGetTokenWithAcceptBid'
      ) {
        await acceptBid(urlParams)
        postMessageToPaywallForGetCustomerBid(urlParams)
        postProcessingCompleteMessageToPaywall(urlParams)
      } else if (urlParams.loginSource === 'amp_paywall') {
        handleWebAmpPaywallAfterLogin(urlParams, customer, fewCentsBidPostLoginNextAction)
      }
    }
  }
}

const handleWebAmpPaywallAfterLogin = (
  urlParams: any,
  customer: any,
  fewCentsBidPostLoginNextAction: any
) => {
  if (
    fewCentsBidPostLoginNextAction === 'redirectToCallCurrencyMisMatch' ||
    fewCentsBidPostLoginNextAction === 'redirectToCallGetTokenWithExistingBid'
  ) {
    logger.log('Going to redirect to AMP server wiuth success=true')
    window.location.href = `${urlParams?.returnUrl}#success=true`
  } else if (fewCentsBidPostLoginNextAction === 'redirectToCallGetTokenWithAcceptBid') {
    logger.log('calling acceptBid')
    acceptBid(urlParams)
  }
}

const acceptBid = async (urlParams: any) => {
  try {
    const payload = {
      fewCentsBidId: urlParams?.loginBidId,
      fewCentsTransactionMode: urlParams?.bidMode === 'tipjar' ? 'tipjar' : 'normal',
      askPrice: urlParams?.bidPrice,
    }
    const accessToken = getAuthToken()
    const response = await fetch(`${config.ApiBaseUrlV2}/fbid/acceptBid`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
    const json = await response.json()
    logger.log('acceptBid response', json)
    window.location.href = `${urlParams?.returnUrl}#success=true`
  } catch (e: any) {
    logger.log('Error while doing acceptAmpBid', e)
    window.location.href = `${urlParams?.returnUrl}#success=true`
  }
}

export default sourceWebPaywall
