interface Environment {
  [x: string]: Configuration
}

interface Configuration {
  ApiBaseUrl: string
  ApiBaseUrlV2: string
  FacebookAppId: string
  GoogleAppId: string
  PaypalClientId: string
  VenmoClientId: string
  AppleClientId: string
  Domain: string
  TestDomain?: string
  SegmentWriteKey?: string
  SegmentAnonymousId?: string
  StripePublishableKey: string
  isRewardsScreen: boolean
  PaywallUrl: string
  CheckoutApiUrl: string
  MonthlySubscription: string
  IsMonthlySubscriptionShow: boolean
}

const configs: Environment = {
  dev: {
    PaywallUrl: 'https://dev.fewcents.co/static/js/paywall.js',
    ApiBaseUrl: 'https://api.hounds.fewcents.co/v1',
    ApiBaseUrlV2: 'https://api.hounds.fewcents.co/v2',
    FacebookAppId: '566170631436954',
    GoogleAppId: '52683014478-7b6790vtstromr5aookelnhad356k0j9.apps.googleusercontent.com',
    AppleClientId: 'co.fewcents.staging.wallet',
    PaypalClientId:
      'AUl-Hdtc3-Ugf6g-ddBzf3EIXgWunIj2Z2DvDq179vBz-HECBY0GvDhoQXPqFk7qfNOum_qESV1oT4dB',
    VenmoClientId:
      'AY6bqYzBCBf7wPl-Sd2odfRefnmsvqg6pjNrkdvBlF9K7D6F3hfD8Uf_NXWxO3wB5ZU2LgHTZF3AFEzO',
    Domain: window.location.host.includes('localhost')
      ? 'http://localhost:3000'
      : 'https://wallet.hounds.fewcents.co',
    SegmentWriteKey: 'IFPpUcfPhlTmsGXTrW7uNkephCWRAvI3',
    SegmentAnonymousId: '98e1e30b-38ac-4ed7-8b0f-78f120fca01a',
    StripePublishableKey:
      'pk_test_51IibvmF0Pxu6i832PNSVoVMlt7nnCLGoM9oE0QGJ92fn7al69lhSkZcbN5YsgF7Fv52XGjqQ05HyvopbKEBtMxJS00C6wVx09Q',
    isRewardsScreen: false,
    CheckoutApiUrl: 'https://api.hounds.fewcents.co/v1',
    MonthlySubscription: 'https://staging.fewcents.me/betabl-thehindu-co',
    IsMonthlySubscriptionShow: true,
  },
  qa: {
    PaywallUrl: 'https://paywall.qa.fewcents.co/static/js/paywall.js',
    ApiBaseUrl: 'https://api.qa.fewcents.co/v1',
    ApiBaseUrlV2: 'https://api.qa.fewcents.co/v2',
    FacebookAppId: '566170631436954',
    GoogleAppId: '52683014478-7b6790vtstromr5aookelnhad356k0j9.apps.googleusercontent.com',
    AppleClientId: 'co.fewcents.staging.wallet',
    PaypalClientId:
      'AUl-Hdtc3-Ugf6g-ddBzf3EIXgWunIj2Z2DvDq179vBz-HECBY0GvDhoQXPqFk7qfNOum_qESV1oT4dB',
    VenmoClientId:
      'AeLyv1fS7fH5kNZPU3UYF617y0Wd8HFlcft4UnCCvb8tqz3MSmMCrT2itf-qbGByr4ujSp24z8qA2ly3',
    Domain: 'https://wallet.qa.fewcents.co',
    SegmentWriteKey: '4EtgVDZdQdxVm6jrZj5BMBNbflOudTKe',
    SegmentAnonymousId: 'b2242c56-2f45-4775-a9e9-f1528f256605',
    StripePublishableKey:
      'pk_test_51IibvmF0Pxu6i832PNSVoVMlt7nnCLGoM9oE0QGJ92fn7al69lhSkZcbN5YsgF7Fv52XGjqQ05HyvopbKEBtMxJS00C6wVx09Q',
    isRewardsScreen: false,
    CheckoutApiUrl: 'https://api.qa.fewcents.co/v1',
    MonthlySubscription: 'https://staging.fewcents.me/betabl-thehindu-co',
    IsMonthlySubscriptionShow: true,
  },
  demo: {
    PaywallUrl: 'https://staging.fewcents.co/static/js/paywall.js',
    ApiBaseUrl: 'https://api.demo.fewcents.co/v1',
    ApiBaseUrlV2: 'https://api.demo.fewcents.co/v2',
    FacebookAppId: '566170631436954',
    GoogleAppId: '52683014478-7b6790vtstromr5aookelnhad356k0j9.apps.googleusercontent.com',
    AppleClientId: 'co.fewcents.staging.wallet',
    PaypalClientId:
      'ASSmSlO-ijUxgFYve7n4w0MornYvOQO9KRDf6RRgT3LxJqQBDvKw_PYTSNTVen0AhffEsDqhsLm6EICV',
    VenmoClientId:
      'AThOhDxLgOdf-Mh-jXmz1TuoAGuX-XRLPvSHAR4581WGQWVE_0GcIlsvGlVJJ8eREz2fvP-3tDFDllfc',
    Domain: 'https://wallet.demo.fewcents.co',
    SegmentWriteKey: '1zKj2OWz036GjgbroTGQ7xhlgKFric0a',
    SegmentAnonymousId: 'c4f5b715-3f31-42a6-8778-c1d571e2ce23',
    StripePublishableKey:
      'pk_test_51IibvmF0Pxu6i832PNSVoVMlt7nnCLGoM9oE0QGJ92fn7al69lhSkZcbN5YsgF7Fv52XGjqQ05HyvopbKEBtMxJS00C6wVx09Q',
    isRewardsScreen: false,
    CheckoutApiUrl: 'http://api.checkout.demo.fewcents.co/v1',
    MonthlySubscription: 'https://staging.fewcents.me/betabl-thehindu-co',
    IsMonthlySubscriptionShow: true,
  },
  prod: {
    PaywallUrl: 'https://paywall.fewcents.co/static/js/paywall.js',
    ApiBaseUrl: 'https://api.fewcents.co/v1',
    ApiBaseUrlV2: 'https://api.fewcents.co/v2',
    FacebookAppId: '566170631436954',
    GoogleAppId: '52683014478-8i20g3lsae6h1kal220emv7i61d644hp.apps.googleusercontent.com',
    AppleClientId: 'co.fewcents.wallet',
    PaypalClientId:
      'AShVXQzDvmrRFif0LEMvcjmeFNulTdAM3Kgy7KvQQd38EI5kagV-l50UZ7ygomHoWRXOGYW-_AMJi8EZ',
    VenmoClientId:
      'AaK3_OYt6zcznIxl3MhhA5p7mnsm5UT-GwIhlcN0uioKpTmnqPl_zof2nzAD1oQBSu3LEvUkwEeMV-aJ',
    Domain: 'https://wallet.fewcents.co',
    SegmentWriteKey: 'ESNRYcxkkWHm321JICBQgcRJrGKDyBQ8',
    SegmentAnonymousId: '4767f188-5124-430d-b6a1-b932e1be1a77',
    StripePublishableKey:
      'pk_test_51IibvmF0Pxu6i832PNSVoVMlt7nnCLGoM9oE0QGJ92fn7al69lhSkZcbN5YsgF7Fv52XGjqQ05HyvopbKEBtMxJS00C6wVx09Q',
    isRewardsScreen: false,
    CheckoutApiUrl: 'https://api.fewcents.co/v1',
    MonthlySubscription: '',
    IsMonthlySubscriptionShow: false,
  },
}

const environment = process.env.REACT_APP_STAGE?.trim() || 'dev'
const config = configs[environment]

export default config
