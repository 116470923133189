import { FC, ReactElement } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getURlParams } from 'utils/sessionStorageFn'
import Wrapper from 'components/Shared/Wrapper'
import useTokenExpiryCheck from 'hooks/useTokenExpiryCheck'
import { useFetchCustomer } from 'hooks/useFetchCustomer'

interface ComponentProps {
  children: ReactElement
  path: string | string[]
}

export const PrivateRoute: FC<ComponentProps> = ({ children, path, ...rest }) => {
  useFetchCustomer()
  const decoded = useTokenExpiryCheck()
  const urlParams = getURlParams()
  return (
    <Wrapper>
      <Route
        path={path}
        {...rest}
        render={({ location }) =>
          decoded ? (
            path === '/loginCheck' && urlParams?.bidMode == undefined ? (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: location },
                }}
              />
            ) : (
              children
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      ></Route>
    </Wrapper>
  )
}
