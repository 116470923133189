import addLoginMethod from 'utils/addLoginMethod'
import {
  getAuthToken,
  getOrCreateDeviceIdentity,
  getRefreshToken,
  removeAuthToken,
  removeRefreshToken,
  setAuthToken,
  setDeviceIdentity,
  setRefreshToken,
} from 'utils/auth'
import decodeToken from 'utils/decodeToken'
import { getURlParams, setURLParams } from 'utils/sessionStorageFn'
import * as analytics from 'utils/analytics'
import config from 'config'
import { handleApiErrors } from 'utils/handleApiErrors'
import getDeviceInfo from 'utils/getDeviceType'
import { DeviceDetail, UrlState } from 'interfaces'
import events from 'utils/eventNames'
import { createLoginKey, getNextToken, redirectByUrl } from 'containers/Login/Utils'
import { logger } from 'utils/logger'

const redirectBackToPaywall = async (history: any) => {
  await redirectByUrl(
    history,
    { pathname: '/loginCheck' },
    {
      accessToken: getAuthToken(),
      refreshToken: getRefreshToken(),
      deviceKey: getOrCreateDeviceIdentity(),
    }
  )
}

export const handleContinueLogin = async (history: any) => {
  addLoginMethod('continue')
  const urlParams = getURlParams()
  const token = await getAuthToken()
  let decoded: any = null
  if (token) {
    decoded = decodeToken(token)
  }
  if (decoded) {
    const expires = new Date(decoded.exp * 1000).getTime()
    const timeout = expires - Date.now()

    if (timeout <= 0) {
      await getNextToken()
    }

    const loginKeyDetailsData = await createLoginKeyAndLinkToUserDevice(urlParams, decoded, history)
    urlParams.loginKey = loginKeyDetailsData?.loginKey
  }
  setURLParams(JSON.stringify({ ...urlParams, loginContinue: true })) //its for handling the events for continue login and passowork not set
  await redirectBackToPaywall(history)
  analytics.track('continue_login', {
    unlockAmount: urlParams?.bidPrice,
    bidMode: urlParams?.bidMode,
  })
  //   return true
}

const createLoginKeyAndLinkToUserDevice = async (urlParams: any, decoded: any, history: any) => {
  try {
    const accessToken = getAuthToken()
    const email = decoded.username
    const deviceKey = getOrCreateDeviceIdentity()

    const response = await fetch(
      `${config.ApiBaseUrl}/customer/createLoginToken/${email}/${deviceKey}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(urlParams),
      }
    )
    const verifiedResponse = handleApiErrors(response)
    const json = await verifiedResponse.json()
    const { data, message, success } = json

    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')

    // TODO - Need to change in future
    if (urlParams?.topupSource) {
      setURLParams(
        JSON.stringify({
          ...data,
          topupSource: urlParams.topupSource,
          bidMode: urlParams.bidMode,
          bidPrice: urlParams.bidPrice,
        })
      )
    } else {
      setURLParams(
        JSON.stringify({
          ...data,
          bidMode: urlParams.bidMode,
          bidPrice: urlParams.bidPrice,
        })
      )
    }
    return data
  } catch (e: any) {
    logger.log(e)
    removeAuthToken()
    removeRefreshToken()
    history.replace({ pathname: '/login' })
  }
}

export const handleSimLogin = async (email: string, history: any) => {
  // const handleSimInline = async () => {
  let device: DeviceDetail = {
    os: null,
    osVersion: null,
    userAgent: null,
    browser: null,
    deviceKey: null,
    type: null,
    modelType: null,
    browserVersion: undefined,
  }

  if (window.navigator) {
    const { userAgent, platform } = window.navigator
    device = getDeviceInfo(userAgent)
  }
  device.deviceKey = getOrCreateDeviceIdentity()
  try {
    addLoginMethod('sim')
    const urlParams = getURlParams()
    const loginKeyDetailsData = await createLoginKey(urlParams)
    urlParams.loginKey = loginKeyDetailsData?.loginKey
    setURLParams(JSON.stringify(urlParams))

    const payload = {
      email: email,
      ...device,
      role: 'member',
      currency: 'SGD',
      loginType: 'sim',
      loginKey: loginKeyDetailsData?.loginKey,
      sourcePublisherId: loginKeyDetailsData?.loginPublisherId
        ? loginKeyDetailsData?.loginPublisherId
        : 0,
      loginToken: loginKeyDetailsData?.loginKey,
      loginSource: 'wallet',
    }

    const response = await fetch(`${config.ApiBaseUrl}/customer/login/sim`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
    const json = await response.json()
    const { data, message, success } = json

    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')

    const { customer, accessToken, refreshToken } = data
    analytics.track(events.continue_as_sim_email)
    if (!customer) throw new Error(`Error while signing in to  SIM`)

    setAuthToken(accessToken)
    setRefreshToken(refreshToken)
    setDeviceIdentity(device.deviceKey)
  } catch (e: any) {
    throw e
  }
  await redirectBackToPaywall(history)
}

export const appendQueryParam = (originalUrl: string, key: string, value: string) => {
  try {
    let query = new URLSearchParams(originalUrl)
    let [first, ..._]: any = query.entries()
    if (first[1].length > 0) {
      query.set(key, value ?? null)
      return decodeURIComponent(query.toString())
    } else {
      return `${originalUrl}?${key}=${value}`
    }
  } catch (e: any) {
    logger.log(e)
    return originalUrl
  }
}

export const getPaywallQueryParams = (location: any): UrlState => {
  let queryParams = null
  if (location.search) {
    const qs = new URLSearchParams(window.location.search)
    queryParams = {
      loginBidId: qs.get('login_publisher_bid_id') || qs.get('topup_publisher_bid_id'),
      loginFailTopupRedirectUrl:
        qs.get('login_fail_topup_redirect_url') || qs.get('topup_redirect_url'),
      loginMethod: qs.get('login_method'),
      loginPublisherId: qs.get('login_publisher_id') || qs.get('topup_publisher_id'),
      loginPublisherLogoUrl:
        qs.get('login_publisher_logo_url') || qs.get('topup_publisher_logo_url'),
      publisherName: qs.get('publisher_name'),
      loginRedirectUrl:
        qs.get('login_redirect_url') ??
        qs.get('topup_redirect_url') ??
        `${config.Domain}/dashboard`,
      loginSimEmail: qs.get('login_sim_user_email'),
      loginSource: qs.get('login_source'),
      topupSource: qs.get('topup_source'),
      bidMode: qs.get('bid_mode'),
      bidTransactionMode: qs.get('transaction_mode'),
      bidPrice: qs.get('bid_price'),
      bidCurrency: qs.get('bidCurrency'),
      bidCountry: qs.get('bidCountry'),
      username: qs.get('user_email'),
      ampReaderId: qs.get('amp_reader_id'),
      returnUrl: qs.get('return_url'),
      rewards: qs.get('rewards') === 'true' ? true : false,
      landingPage: qs.get('landing_page'),
      uxMode: window.history.length > 1 ? 'redirect' : 'postMessage',
      language: qs.get('language') ? qs.get('language') : 'en',
    }
  } else {
    queryParams = {
      loginSource: 'wallet',
      loginRedirectUrl: `${config.Domain}/dashboard`,
      uxMode: window.history.length > 1 ? 'redirect' : 'postMessage',
      language: 'en',
    }
  }

  setURLParams(JSON.stringify({ ...queryParams }))
  return queryParams
}

export const getAccessToken = () => {
  let accessToken = getAuthToken()
  if (accessToken && decodeToken(accessToken).username.includes('fc_guest_')) {
    removeAuthToken()
    accessToken = null
  }
  return accessToken
}
