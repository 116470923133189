import { logger } from 'utils/logger'

const sourceIosPaywall = (urlParams: any, customer: any) => {
  try {
    const inAppWebView: any = window
    let data = {
      login_key: urlParams?.loginKey,
      device_key: customer?.deviceKey,
    }
    inAppWebView.webkit && inAppWebView.webkit.messageHandlers.fcHandler.postMessage(data)
  } catch (error) {
    logger.log('Error while posting message to fewcents ios SDK', error)
  }
}

export default sourceIosPaywall
