import { FC, Suspense, lazy, useEffect } from 'react'
import * as analytics from 'utils/analytics'
import events from 'utils/eventNames'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { PrivateRoute } from 'components/Shared/PrivateRoute'
import Loading from 'components/Shared/Loading'
import RootPage from 'containers/RootPage'
import { useDispatch } from 'react-redux'
import { updateDisplay } from 'actions/display'
import { CallbackStatus } from 'components/Rapyd/RapydStatus'
import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'
import { theme } from './theme'
import { FormCallbackStatus } from 'containers/Login/FormStatus'
import config from 'config'

interface Props {
  error: any
  resetErrorBoundary: any
}

const ErrorFallback: FC<Props> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
const RapydStatus = lazy(() => import('components/Rapyd/RapydStatus'))
const Dashboard = lazy(() => import('containers/Dashboard'))
const Account = lazy(() => import('containers/Account'))
const Articles = lazy(() => import('containers/Articles'))
const Transactions = lazy(() => import('containers/Transactions'))
const LoginCheckout = lazy(() => import('containers/Login'))
const ResetPassword = lazy(() => import('components/ResetPassword'))
const Rewards = lazy(() => import('containers/Rewards'))
const FormStatus = lazy(() => import('containers/Login/FormStatus'))
const UnsubscribeEmail = lazy(() => import('containers/Login/UnsubscribeEmail'))

const App: FC = () => {
  const dispatch = useDispatch()
  window.addEventListener('resize', () => dispatch(updateDisplay()))

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowClose)
    function handleWindowClose(evt: any) {
      analytics.track(events.browser_closed)
    }
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose)
    }
  }, [])

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/payment-success">
                <RapydStatus
                  event="payment_successful"
                  status={CallbackStatus.PaymentSuccess}
                />
              </Route>
              <Route path="/payment-error">
                <RapydStatus
                  event="payment_failed"
                  status={CallbackStatus.PaymentError}
                />
              </Route>
              <Route path="/checkout-complete">
                <RapydStatus
                  event="checkout_successful"
                  status={CallbackStatus.CheckoutCompleted}
                />
              </Route>
              <Route path="/checkout-cancel">
                <RapydStatus
                  event="checkout_canceled"
                  status={CallbackStatus.CheckoutCanceled}
                />
              </Route>

              <Route path="/survey-error">
                <FormStatus
                  event="survey_failed"
                  status={FormCallbackStatus.Failed}
                />
              </Route>
              <Route path="/survey-success">
                <FormStatus
                  event="survey_success"
                  status={FormCallbackStatus.Success}
                />
              </Route>
              <Route path="/reset-password">
                <ResetPassword />
              </Route>
              <Route path="/login">
                <LoginCheckout />
              </Route>
              <PrivateRoute path="/loginCheck">
                <LoginCheckout />
              </PrivateRoute>
              <Route path="/unsubscribe">
                <UnsubscribeEmail />
              </Route>
              <PrivateRoute path="/account">
                <Account />
              </PrivateRoute>
              <PrivateRoute path="/articles">
                <Articles />
              </PrivateRoute>
              {config.isRewardsScreen && (
                <PrivateRoute path="/rewards">
                  <Rewards />
                </PrivateRoute>
              )}
              <PrivateRoute path="/transactions">
                <Transactions />
              </PrivateRoute>
              <PrivateRoute path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <Route path="/">
                <RootPage />
              </Route>
            </Switch>
          </Suspense>
        </Router>
        <Toaster toastOptions={{ style: { textAlign: 'center' }, duration: 2200 }} />
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
