import config from 'config'
import { PaypalTransactionPayload } from 'interfaces'
import { getAuthToken, getDeviceIdentity } from 'utils/auth'
import { handleApiErrors } from 'utils/handleApiErrors'
import * as analytics from 'utils/analytics'
import { appendQueryParam } from 'containers/RootPage/helper'
import { logger } from 'utils/logger'

export const acceptBidPostCheckout = async (urlParams: any, contentToBeUnlocked: Boolean) => {
  const payload = {
    fewCentsBidId: urlParams?.loginBidId,
    fewCentsTransactionMode:
      urlParams?.bidTransactionMode === 'daily_pass'
        ? 'daily_pass_paid'
        : urlParams?.bidMode === 'tipjar'
        ? 'tipjar'
        : 'normal',
    askPrice: urlParams?.bidPrice,
    contentToBeUnlocked,
  }

  const accessToken = getAuthToken()
  const response = await fetch(`${config.ApiBaseUrlV2}/fbid/acceptBidPostCheckout`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify(payload),
  })
  const json = await response.json()
}

export const acceptBidPostRewardClaim = async (urlParams: any, customerRewardWalletId: number) => {
  const payload = {
    fewCentsBidId: urlParams?.loginBidId,
    fewCentsTransactionMode:
      urlParams?.bidTransactionMode === 'daily_pass'
        ? 'daily_pass_paid'
        : urlParams?.bidMode === 'tipjar'
        ? 'tipjar'
        : 'normal',
    askPrice: urlParams?.bidPrice,
    customerRewardWalletId,
  }

  const accessToken = getAuthToken()
  const response = await fetch(`${config.ApiBaseUrlV2}/fbid/acceptBidPostRewardClaim`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify(payload),
  })
  const json = await response.json()
}

export const postMessageToPaywall = (urlParams: any, indicateProcessingComplete = false) => {
  const isPaywallLoginFlow = urlParams?.loginSource && urlParams?.loginSource !== 'wallet'
  const isPaywallCheckoutFlow = urlParams?.topupSource && urlParams?.topupSource === 'paywall'

  if (isPaywallLoginFlow) {
    postMessageToPaywallForGetTokenAndExistingBid(urlParams)
  } else if (isPaywallCheckoutFlow) {
    postMessageToPaywallForGetCustomerBid(urlParams)
  }
  if (indicateProcessingComplete) {
    postProcessingCompleteMessageToPaywall(urlParams)
  }
}

export const postProcessingCompleteMessageToPaywall = (
  urlParams: any,
  overrideRedirect?: boolean
) => {
  const loginKey = urlParams?.loginKey
  const deviceKey = getDeviceIdentity()
  let redirectUrl = new URL(urlParams?.loginRedirectUrl)
  let targetOrigin = `${redirectUrl.protocol}//${redirectUrl.host}`
  let data = {
    status: 'complete',
  }
  if (
    window.opener &&
    !window.opener.closed &&
    typeof window.opener.postMessage === 'function' &&
    urlParams.uxMode === 'postMessage'
  ) {
    window.opener.postMessage(`complete:${JSON.stringify(data)}`, targetOrigin)
    // setTimeout(() => window.close())
  } else {
    if (!overrideRedirect)
      redirectToPaywall(
        redirectUrl,
        'postProcessingCompleteMessageToPaywall',
        loginKey,
        deviceKey,
        'redirectToCallGetTokenWithExistingBid'
      )
  }
}

const postMessageToPaywallForGetTokenAndExistingBid = (urlParams: any) => {
  if (urlParams.loginSource === 'paywall') {
    postMessageToWebPaywallAfterLogin(urlParams, 'redirectToCallGetTokenWithExistingBid')
  } else if (urlParams.loginSource === 'amp_paywall') {
    window.location.href = `${urlParams?.returnUrl}#success=true`
  }
}

export const postMessageToPaywallForGetCustomerBid = (urlParams: any) => {
  postNextActionToPaywall(
    urlParams,
    'topup',
    'redirectToCallGetTokenWithExistingBid',
    'postMessageToPaywallForGetCustomerBid'
  )
}

export const postMessageToPaywallForCurrencyMismatchDuringCheckout = (urlParams: any) => {
  postNextActionToPaywall(
    urlParams,
    'topup',
    'redirectToCallCurrencyMisMatch',
    'postMessageToPaywallForCurrencyMismatchDuringCheckout'
  )
}

export const postMessageToWebPaywallAfterLogin = (urlParams: any, nextAction: string) => {
  /** Handle key passing with postMessage */
  postNextActionToPaywall(urlParams, 'login', nextAction, 'postMessageToWebPaywallAfterLogin')
}

const postNextActionToPaywall = (
  urlParams: any,
  flow: string,
  nextAction: string,
  walletAction: string
) => {
  /** Handle key passing with postMessage */
  const loginKey = urlParams?.loginKey
  const deviceKey = getDeviceIdentity()
  let data = {
    login_key: loginKey,
    device_key: deviceKey,
    next_action: nextAction,
    status: 'success',
  }
  let redirectUrl = new URL(urlParams?.loginRedirectUrl)
  let targetOrigin = `${redirectUrl.protocol}//${redirectUrl.host}`
  if (
    window.opener &&
    !window.opener.closed &&
    typeof window.opener.postMessage === 'function' &&
    urlParams.uxMode === 'postMessage'
  ) {
    window.opener.postMessage(`${flow}:${JSON.stringify(data)}`, targetOrigin)
  } else {
    redirectToPaywall(redirectUrl, walletAction, loginKey, deviceKey, nextAction)
  }
}

const redirectToPaywall = (
  redirectUrl: URL,
  walletAction: string,
  loginKey?: string,
  deviceKey?: string,
  nextAction?: string
) => {
  let urlToBeRedirected = `${redirectUrl}`
  if (loginKey && deviceKey && nextAction) {
    urlToBeRedirected = appendQueryParam(redirectUrl.toString(), 'login_key', loginKey)
    urlToBeRedirected = appendQueryParam(urlToBeRedirected, 'device_key', deviceKey)
    urlToBeRedirected = appendQueryParam(urlToBeRedirected, 'next_action', nextAction)
  }

  analytics.track('redirecting_back_to_paywall', {
    urlToBeRedirected,
    walletAction,
  })
  window.location.href = urlToBeRedirected
}

export const paypalTransactionCall = async (id: number, payload: PaypalTransactionPayload) => {
  const accessToken = getAuthToken()
  try {
    const response = await fetch(`${config.ApiBaseUrl}/customer/${id}/checkout/paypal`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
    const verifiedResponse = handleApiErrors(response)
    const json = await verifiedResponse.json()
    const { data, message, success } = json
    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')
  } catch (e: any) {
    logger.log('Some issue while calling the api.')
  }
}
