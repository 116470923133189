import { UPDATE_METRICS_DATA } from 'action-types'

interface State {
  visits: number | null
  unlocks: number | null
  paidUnlocks: number | null
  tipped: number | null
  barData: any | null
  pieData: any | null
}

const initial_state: State = {
  visits: null,
  unlocks: null,
  paidUnlocks: null,
  tipped: null,
  barData: null,
  pieData: null,
}

const metrics = (state = initial_state, action: any) => {
  switch (action.type) {
    case UPDATE_METRICS_DATA:
      return {
        ...state,
        visits: action.visits,
        unlocks: action.unlocks,
        paidUnlocks: action.paidUnlocks,
        tipped: action.tipped,
        barData: action.barData,
        pieData: action.pieData,
      }
    default:
      return state
  }
}

export default metrics
