import { EDIT_CUSTOMER, GUEST_EMAIL, UPDATE_TOP_PAYMENT_METHODS } from 'action-types'
import config from 'config'
import { handleApiErrors } from 'utils/handleApiErrors'
import { logger } from 'utils/logger'

const getAvailablePaymentMethods = async (homeCurrency?: string, homeCountry?: string) => {
  let baseurl
  if (homeCurrency && homeCountry) {
    baseurl = `${config.ApiBaseUrl}/analytics/availablePmtMethods?currency=${homeCurrency}&country=${homeCountry}`
  } else {
    baseurl = `${config.ApiBaseUrl}/analytics/availablePmtMethods`
  }
  try {
    const response = await fetch(baseurl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const verifiedResponse = handleApiErrors(response)
    const json = await verifiedResponse.json()
    const { data, message, success } = json
    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')
    return data
  } catch (e: any) {
    logger.error('Some issue while calling the api.')
    throw e
  }
}

export const editCustomer = (customer: any) => ({
  type: EDIT_CUSTOMER,
  customer,
})

export const setGuestEmail = (email: string) => ({
  type: GUEST_EMAIL,
  email,
})

export const updatePaymentMethods =
  (country: string, currency: string) => async (dispatch: any) => {
    try {
      const data = await getAvailablePaymentMethods(country, currency)
      dispatch({
        type: UPDATE_TOP_PAYMENT_METHODS,
        payload: data,
      })
    } catch (error) {
      logger.error(error)
    }
  }
